import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, Paper, Divider } from '@material-ui/core';
import { AppContext } from 'AppContext';
import { useForm } from 'react-hook-form';
import { ECTextField } from 'components/Forms/ECTextField';
import { ECCommonErrorBlock } from 'components/Forms/ECCommonErrorBlock';
import { errorsToForm } from 'components/Forms/form_utils';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '480px',
    marginBottom: '32px',
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    padding: '0 32px 20px',
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  caption: {
    paddingRight: 40,
  },
  divider: {
    margin: theme.spacing(2, 0, 1),
  },
  textField: {
    margin: theme.spacing(2, 0, 0),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

export const fields = ['username', 'password'];

const SignIn = () => {
  const classes = useStyles();
  const form = useForm();
  const appContext = useContext(AppContext);

  const handleSignIn = data => {
    appContext.authApi
      .login({
        username: data.username,
        password: data.password,
      })
      .then(payload => {
        appContext.login(payload.data);
        appContext.history.push('/');
      })
      .catch(errorsToForm(form));
  };

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer} elevation={0}>
        <Paper className={classes.content}>
          <div className={classes.contentBody}>
            <form className={classes.form} onSubmit={form.handleSubmit(handleSignIn)}>
              <Typography className={classes.title} variant='h3'>
                Just-AI Conversation Platform
              </Typography>
              <Typography className={classes.subtitle} variant='h5'>
                Управление конфигурацией
              </Typography>
              <Divider className={classes.divider} />
              <div style={{ paddingRight: 80 }}>
                <Typography className={classes.caption} variant='caption'>
                  Используйте ранее заданный Вами пароль или найдите дефолтный пароль в вашем дистрибутиве.
                </Typography>
              </div>
              <ECCommonErrorBlock form={form} className={classes.textField}/>
              <ECTextField className={classes.textField} label='Логин' name={`username`} form={form} />
              <ECTextField className={classes.textField} label='Пароль' name={`password`} form={form} type='password' />
              <Button className={classes.signInButton} color='primary' fullWidth type='submit' variant='contained'>
                Войти
              </Button>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

export default SignIn;
