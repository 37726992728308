import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  buttonBlock: {
    '& > button': {
      marginLeft: '7px',
      marginRight: '7px',
    },
  },
}));

type Props = {
    children: React.ReactNode;
    className?: string;
};

export const ButtonBlock = ({ children, className } : Props) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.buttonBlock, className)}>
            {children}
        </div>
    );
};