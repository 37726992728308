import { Reducer } from 'react';
import { MutedCheckData, CheckData, } from 'api';

type PageState = {
  mutedChecks?: MutedCheckData[],
  checks?: CheckData[];
  selectedMute?: MutedCheckData;
};

type PageAction =
  { type: 'addNewMutePageInit';
    payload: {
      mutedChecks?: MutedCheckData[],
      checks?: CheckData[]
    }
  } |
  { type: 'selectedMutePageInit';
    payload: {
      mutedChecks?: MutedCheckData[],
      checks?: CheckData[],
      selectedMute?: MutedCheckData
    }
  } |
  { type: 'saveNewMute';
    payload: {
      mutedChecks?: MutedCheckData[],
      selectedMute?: MutedCheckData
    }
  } |
  { type: 'updateMute'; payload?: MutedCheckData[] } |
  { type: 'setMutes'; payload?: MutedCheckData[] } |
  { type: 'addNewMute'; payload?: MutedCheckData[] } |
  { type: 'deleteMute'; payload?: MutedCheckData[] } |
  { type: 'deleteSelectedMute';
    payload:{
      mutedChecks?: MutedCheckData[],
      selectedMute?: MutedCheckData
    }
  } |
  { type: 'setSelectedMute'; payload?: MutedCheckData };

type PageReducer = Reducer<PageState, PageAction>;

export const initialPageState: PageState = {
  mutedChecks: undefined,
  checks: undefined,
  selectedMute: undefined,
};

export const reducer: PageReducer = (state, action) => {
  switch (action.type) {
    case 'addNewMutePageInit':
      return { ...state, mutedChecks: action.payload.mutedChecks, checks: action.payload.checks };
    case 'selectedMutePageInit':
      return { ...state, mutedChecks: action.payload.mutedChecks, checks: action.payload.checks, selectedMute: action.payload.selectedMute };
    case 'saveNewMute':
      return { ...state, mutedChecks: action.payload.mutedChecks, selectedMute: action.payload.selectedMute };
    case 'updateMute':
      return { ...state, mutedChecks: action.payload };
    case 'setMutes':
      return { ...state, mutedChecks: action.payload };
    case 'deleteMute':
      return { ...state, mutedChecks: action.payload };
    case 'deleteSelectedMute':
      return { ...state, mutedChecks: action.payload.mutedChecks, selectedMute: action.payload.selectedMute };
    case 'setSelectedMute':
      return { ...state, selectedMute: action.payload };
    default:
      throw new Error();
  }
};
