import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import themeDefault from './theme';
import './assets/scss/index.scss';
import {AppContext, AppContextProvider} from "./AppContext";
import { RouteWithLayout } from './components';
import { Switch, Redirect } from 'react-router-dom';
import LoadingSpinner from "./components/LoadingSpinner";
import Favicon from "react-favicon";

import Overview from "./views/Overview/Overview";
import SignIn from "./views/SignIn/SignIn";
import Security from "./views/Security/Security";
import BasicConfig from "./views/BasicConfig/BasicConfig";
import NotFound from "./views/NotFound";
import Status from "./views/Status/Status";
import StatusAccessibility from "./views/StatusAccessibility/StatusAccessibility";
import MutedChecks from "./views/MutedChecks";

import Minimal from "./layouts/Minimal/Minimal";
import Main from "./layouts/Main/Main";

import { ApplyChangesModal } from 'components/ApplyChangesModal/ApplyChangesModal';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

const browserHistory = createBrowserHistory();

export default class App extends Component {
  render() {
    const arr = window.location.href.split("/");
    const url = arr[0] + "//" + arr[2];

    const theme = themeDefault;
    document.title = theme.documentTitle;
    return (
      <ThemeProvider theme={theme}>
        <Favicon url={theme.topIcon} />
        <AppContextProvider apiUrl={url} history={browserHistory}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'ru'}>
            <AppContext.Consumer>
              {({open, loading, handleSaveChanges, handleShowFinishChanges, handleRollbackChanges, handleAbortChanges, handleClose, stateData}) =>
                <>
                  {loading?
                    <LoadingSpinner/>
                    : null
                  }
                  <ApplyChangesModal
                    open={open}
                    handleSubmit={handleSaveChanges}
                    handleShowFinish={handleShowFinishChanges}
                    handleRollback={handleRollbackChanges}
                    handleReject={handleAbortChanges}
                    onClose={handleClose}
                    stateData={stateData}
                  />
                </>
              }
            </AppContext.Consumer>
            <Router history={browserHistory}>
              <Switch>
                <Redirect
                  exact
                  from="/"
                  to="/status-accessibility"
                />
                <RouteWithLayout
                  component={SignIn}
                  exact
                  layout={Minimal}
                  path="/sign-in"
                />
                <RouteWithLayout
                  component={Security}
                  exact
                  layout={Main}
                  path="/security"
                  auth={true}
                />
                <RouteWithLayout
                  component={Overview}
                  layout={Main}
                  path="/overview"
                  auth={true}
                />
                <RouteWithLayout
                  component={BasicConfig}
                  layout={Main}
                  path="/basic"
                  auth={true}
                />
                <RouteWithLayout
                  component={Status}
                  layout={Main}
                  path="/status"
                  auth={true}
                />
                <RouteWithLayout
                  component={StatusAccessibility}
                  layout={Main}
                  path="/status-accessibility"
                  auth={true}
                />
                <RouteWithLayout
                  component={MutedChecks}
                  layout={Main}
                  path="/muted-checks"
                  auth={true}
                />
                <RouteWithLayout
                  component={NotFound}
                  exact
                  layout={Minimal}
                  path="/not-found"
                />
                <Redirect to="/not-found" />
              </Switch>
            </Router>
          </MuiPickersUtilsProvider>
        </AppContextProvider>
      </ThemeProvider>
    );
  }
}
