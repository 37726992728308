import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  makeStyles,
} from '@material-ui/core';
import { AppContext } from '../../../AppContext';
import { useGlobalLoading } from 'hooks';
import { StatLogData } from 'api';
import { EventLogProps } from './types';

const formatTimestamp = (tiemstampTest?: string) => {
  return moment(tiemstampTest).format('DD.MM.YYYY, HH:mm:ss');
};

const applyPagination = ({data, page, limit} :
  { data?: StatLogData[]; page: number; limit: number }) => {
  return data ? data.slice(page * limit, page * limit + limit) : null;
}

const useStyles = makeStyles(theme => ({
  root: {},
  tableCell: {
    wordBreak: 'break-all',
  },
}));

function EventLog({ className, ...rest }: EventLogProps) {
  const classes = useStyles();
  const [logs, setLogs] = useState<StatLogData[] | undefined>(undefined);
  const appContext = useContext(AppContext);
  const [isLoading, load] = useGlobalLoading();

  useEffect(() => {
    load(appContext.accountApi.listStatLog()
      .then(payload => {
        const { statLogDataList } = payload.data;
        setLogs(statLogDataList);
      })
      .catch(error => {
        setLogs(undefined);
      })
    );
  }, []);

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

  type handlePageChangeType = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  const handlePageChange: handlePageChangeType = (event, newPage) => {
    setPage(newPage);
  };

  type handleLimitChangeType = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  const handleLimitChange: handleLimitChangeType = event => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedLogs = applyPagination({ data: logs, page, limit });

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title='Журнал действий' />
      <Divider />
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Время</TableCell>
                <TableCell>Действие</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedLogs?.map(log => (
                  <TableRow>
                    <TableCell className={classes.tableCell}>{formatTimestamp(log.timestamp)}</TableCell>
                    <TableCell className={classes.tableCell}>{log.message}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component='div'
            count={logs ? logs.length : 1}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Box>
      </PerfectScrollbar>
      <Box p={2} display='flex' justifyContent='flex-end'></Box>
    </Card>
  );
}

export default EventLog;
