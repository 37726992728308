import React from 'react';
import { DetailsModalProps } from './types';
import { StatusCheckDetailedHistoryData } from 'api';
import { StatusDetailedModal } from 'components/StatusDetailedModal/StatusDetailedModal';
import { DetailedStatusItemDropdown } from './DetailedStatusItemDropdown';

export const DetailsModal = React.memo(
  ({ className, open, onClose, checkDetailedHistoryData, getDetailedHistory, ...rest }: DetailsModalProps) => {
    return (
      <StatusDetailedModal<StatusCheckDetailedHistoryData>
        open={open}
        onClose={onClose}
        getDetailedHistory={getDetailedHistory}
        checkDetailedHistoryData={checkDetailedHistoryData}
        renderStatusItem={(detailedHistory?: StatusCheckDetailedHistoryData, key?: number) => (
          <DetailedStatusItemDropdown {...detailedHistory} key={key} />
        )}
      />
    );
  }
);
