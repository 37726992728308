import React, { useState, useMemo } from 'react';
import { List, ListItem, ListItemText, Collapse, Divider, makeStyles } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { StatusCheckDetailedHistoryData, StatusCheckResult } from 'api';
import { formatTimestamp, JSONPrettify } from './utils';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: '8px',
  },
  statusItemDropdownContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '5px 0 5px 10px',
    background: theme.palette.grey[100],
    '& > span': {
      fontSize: '14px',
      padding: '4px 0',
    },
  },
  status: {
    paddingBottom: '5px',
  },
  resultContainer: {
    fontSize: '14px',
    display: 'grid',
    gridTemplateColumns: '15% 85%',
    gridTemplateRows: '1fr',
    wordBreak: 'break-all',
    '& > div': {
      display: 'flex',
      '&:nth-child(2n)': {
        flexDirection: 'column',
      },
    },
    '& p': {
      whiteSpace: 'normal',
      padding: '0 10px 0 0',
      lineHeight: '1.5',
    },
  },
}));

const ResultContainer = ({
  timestamp = '',
  reasonPhase = '',
  statusValue = 0,
  response = '',
  totalTime = 0,
}: StatusCheckResult) => {
  const classes = useStyles();
  const responseToRender = useMemo(() => JSONPrettify(response), [response]);
  const totalTimeToRender = useMemo(() => (totalTime ? `, ${totalTime} ms` : ''), [totalTime]);
  return (
    <div className={classes.resultContainer}>
      <div>
        <span>{formatTimestamp(timestamp)}</span>
      </div>
      <div>
        <span className={classes.status}>{`${statusValue} ${reasonPhase}${totalTimeToRender}`}</span>
        <div>
          <pre
            style={{
              display: 'block',
              whiteSpace: 'break-spaces',
            }}
          >
            {responseToRender}
          </pre>
        </div>
      </div>
    </div>
  );
};

export const DetailedStatusItemDropdown = React.memo(
  ({
    startTime,
    endTime,
    id = 0,
    status,
    firstResult,
    lastResult,
    checksQuantity,
  }: StatusCheckDetailedHistoryData & { id?: number }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleClick = () => {
      setOpen(!open);
    };

    const renderStatus = useMemo(
      () => `${formatTimestamp(startTime)} - ${formatTimestamp(endTime)}: ${status} (${checksQuantity})`,
      [startTime, endTime, status]
    );
    return (
      <>
        <ListItem className={classes.listItem} key={id} button onClick={handleClick}>
          <ListItemText primary={renderStatus} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List disablePadding>
            <ListItem disableGutters className={classes.statusItemDropdownContent}>
              <ResultContainer {...firstResult} />
            </ListItem>
            <Divider />
            <ListItem disableGutters className={classes.statusItemDropdownContent}>
              <ResultContainer {...lastResult} />
            </ListItem>
          </List>
          <Divider />
        </Collapse>
      </>
    );
  }
);
