import { Reducer } from 'react';
import { periodTypes } from './components/types';
import { HistoryData, CheckHistoryData, DetailedHistoryData } from 'api';

type PageState = {
  detailedHistoryData?: DetailedHistoryData;
  openModal: boolean;
  dateStartModal?: string;
  periodType: periodTypes;
  historyData?: CheckHistoryData[];
  env?: string;
};

type PageAction =
  { type: 'setDetailedHistory'; payload?: DetailedHistoryData } |
  { type: 'setPeriodTypeDay' } |
  { type: 'setPeriodTypeHour' } |
  { type: 'setHistoryData'; payload?: HistoryData };

type PageReducer = Reducer<PageState, PageAction>;

export const initialPageState: PageState = {
  detailedHistoryData: undefined,
  openModal: false,
  dateStartModal: undefined,
  periodType: periodTypes.hour,
  historyData: undefined,
  env: undefined,
};

export const reducer: PageReducer = (state, action) => {
  switch (action.type) {
    case 'setDetailedHistory':
      return { ...state, detailedHistoryData: action.payload };
    case 'setPeriodTypeDay':
      return { ...state, periodType: periodTypes.day };
    case 'setPeriodTypeHour':
      return { ...state, periodType: periodTypes.hour };
    case 'setHistoryData':
      return { ...state, historyData: action.payload?.historyData, env: action.payload?.env };
    default:
      throw new Error();
  }
};
