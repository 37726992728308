import { MenuItem, TextField, Chip } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import {FormContextValues, FieldValues} from 'react-hook-form';

export const MultiSelectControlled = <T extends object>(props : MultiSelectControlledProps<T> & { children?: React.ReactNode }) => {
  const {
    name,
    label,
    options,
    form,
    getItemId = (item: { id: string, name: string }) => item.id,
    getItemName = (item: { id: string, name: string }) => item.name,
    defaultValue,
    initial,
    ...rest
  } = props;

  const renderValue = (value: any[] = []) => {
    return (
        <div>
            {value.map((value, index) => (
              <Chip
                key={value?.id}
                label={`${value?.displayName}`}
                style={{margin: 2}}
              />
            ))}
        </div>
    )
  };

  return (
    <Controller
      as={<TextField />}
      control={form.control}
      label={label}
      name={name}
      select
      fullWidth
      variant="outlined"
      helperText={form.errors[name] && form.errors[name].message}
      error={!!form.errors[name]}
      SelectProps={{
          multiple: true,
          renderValue: renderValue,
      }}
      {...rest}
    >
      {options.map(item => (
        <MenuItem key={item} value={item}>
          {getItemName(item)}
        </MenuItem>
      ))}
    </Controller>
  );
};

interface MultiSelectControlledProps<T> {
  name: string;
  label: string;
  options: any[];
  getItemName?: (item: T) => string;
  getItemId?: (item: T) => string | number;
  form: FormContextValues<FieldValues>;
  defaultValue?: T[];
  initial?: T[];
}