import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ECTextField } from 'components/Forms/ECTextField';
import { ECCommonErrorBlock } from 'components/Forms/ECCommonErrorBlock';
// import { errorsToForm } from 'components/Forms/form_utils'; TODO possible add errors handler to form
import { ChangePasswordProps, ChangePasswordFields } from './types';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

function ChangePassword({ className, ...rest }: ChangePasswordProps) {
  const classes = useStyles();
  const form = useForm<ChangePasswordFields>();
  // const appContext = useContext(AppContext); TODO possible logic for password change

  const handleChangePassword = (formFieldsData: ChangePasswordFields) => {
    //TODO possible add axios request for password change
    //.catch(errorsToForm(form));
  };

  return (
    <form onSubmit={form.handleSubmit(handleChangePassword)}>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title='Change Password' />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} sm={6} xs={12}>
              <ECTextField
                label='Password'
                name='password'
                form={form}
                {...{ type: 'password', margin: 'none' }}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <ECTextField
                label='Password Confirmation'
                name='passwordConfirm'
                form={form}
                {...{ type: 'password', margin: 'none' }}
              />
            </Grid>
            <ECCommonErrorBlock form={form} />
          </Grid>
        </CardContent>
        <Box p={2} display='flex' justifyContent='flex-end'>
          <Button color='secondary' type='submit' variant='contained'>
            Save Changes
          </Button>
        </Box>
      </Card>
    </form>
  );
}

export default ChangePassword;
