import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  makeStyles,
} from '@material-ui/core';
import { AppContext } from '../../AppContext';
import { useForm } from 'react-hook-form';
import { ItemConfig } from './components/ItemConfig';
import { ButtonBlock } from 'components/ButtonBlock/ButtonBlock';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 80,
  },
}));

function BasicConfig({ className, title, ...rest }) {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const form = useForm();
  const [configData, setConfigData] = useState(undefined);
  useEffect(() => {
    appContext.configApi
      .getConfig()
      .then(payload => {
        setConfigData(payload.data);
      })
      .catch(appContext.errorHandler);
  }, []);

  useEffect(() => {
    reset();
  }, [configData]);

  const handleSave = data => {
    if (configData && data.configs) {
      const dataToSave = {
        configId: configData.configId,
        componentList: data.configs.map(config => {
          return { urlData: config };
        }),
      };
      appContext.configApi
        .updateConfig(dataToSave)
        .then(payload => {
          setConfigData(payload.data);
          appContext.checkAppState();
        })
        .catch(appContext.errorHandler);
    }
  };

  const reset = () => {
    if (configData && configData.componentList) {
      form.reset({
        configs: configData.componentList.map(config => config && config.urlData),
      });
    }
  };

  return (
    <Container>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title={'Адреса и маршруты'} />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Компонент</TableCell>
                  <TableCell>Доменное имя</TableCell>
                  <TableCell>SSL</TableCell>
                  <TableCell>Let's Encrypt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {configData?.componentList?.map((config, i) => {
                    return config?.urlData && <ItemConfig id={i} initial={config.urlData} form={form} />;
                  })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Box p={2} display='flex' justifyContent='flex-end'>
          <ButtonBlock>
            <Button color='primary' type='submit' onClick={reset} variant='contained'>
              Reset
            </Button>
            <Button color='secondary' type='submit' onClick={form.handleSubmit(handleSave)} variant='contained'>
              Save Changes
            </Button>
          </ButtonBlock>
        </Box>
      </Card>
    </Container>
  );
}

BasicConfig.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default BasicConfig;
