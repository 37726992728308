import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MutedChecks } from './MutedChecks';

export const MutedChecksRoutes = () => {
    return (
        <Switch>
            <Route exact path={`/muted-checks/:id`}>
                <MutedChecks />
            </Route>
            <Redirect to={`/muted-checks/add-new-mute`} />
        </Switch>
    )
};

export default MutedChecksRoutes;