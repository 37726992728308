import React, { useCallback, useState, useEffect, useContext, useMemo } from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    Typography,
    Grid,
    makeStyles
} from '@material-ui/core';
import { ECTextField } from 'components/Forms/ECTextField';
import { ECCommonErrorBlock } from 'components/Forms/ECCommonErrorBlock';
import { ECDateTimeField } from 'components/Forms/ECDateTimeField';
import { ECSelectField } from 'components/Forms/ECSelectField';
import { MutedChecksFormProps } from './types';
import { statusOptions } from './constants';
import { Controller } from 'react-hook-form';
import { CheckData } from 'api';
import { MultiSelectControlled } from 'components/Forms/MultiSelectControlled';
import { ButtonBlock } from 'components/ButtonBlock/ButtonBlock';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    button: {
      paddingLeft: '40px',
      paddingRight: '40px',
    },
    containerForm: {
      display: 'flex',
      height: 'fit-content',
    },
    buttonSuccess: {
      backgroundColor: theme.palette.success.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    },
    commonErrorsBlock: {
      width: '100%',
      margin: '8px 4px',
    },
    title: {
      textAlign: 'center',
      padding: '9px',
      fontSize: '18px',
    },
  }));

// const getBindingName = (binding: BindingData): string => {
//   return `${binding?.inventory} <- ${binding?.playbook} (${binding?.repo})`;
// };

export const MutedChecksForm = ({ form, checks, handleSave, handleReset, isUpdating = false, updatingMuteOldDate = "" }: MutedChecksFormProps) => {
    const classes = useStyles();
    const formattedOldDateStart = useMemo(() => moment(updatingMuteOldDate).format('D MMMM, HH:mm'), [updatingMuteOldDate]);
    return (
        <div className={classes.containerForm}>
          <Grid container spacing={1}>
              <Grid item xs={12} justifyContent="center">
                <Typography variant='h4' className={classes.title}>
                  {`${isUpdating? `Редактирование (${formattedOldDateStart})` : `Добавление`} заглушенной проверки`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <ECDateTimeField label='Начало' name='dateStart' form={form} initial={{dateStart: ''}} {...{ margin: 'none' }}/>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={ECTextField}
                  label='Длительность'
                  name={`duration`}
                  control={form.control}
                  defaultValue={''}
                  form={form}
                  {...{ margin: 'none' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={ECTextField}
                  label='Описание проводимых работ'
                  name={`comment`}
                  control={form.control}
                  defaultValue={''}
                  form={form}
                  {...{ margin: 'none', multiline: true, minRows: 4}}
                />
              </Grid>
              <Grid item xs={12}>
                {checks &&
                  <MultiSelectControlled<CheckData>
                    label='Выключаемые проверки'
                    name='checks'
                    options={checks}
                    getItemId={(check: CheckData) => check?.id || 0}
                    getItemName={(check: CheckData) => check?.displayName || ""}
                    form={form}
                    {...{ margin: 'none', padding: 0 }}
                  />
                }
              </Grid>
              {isUpdating &&
                <Grid item xs={12}>
                  <ECSelectField
                    label='Cтатус'
                    name='status'
                    fid={it => it.name}
                    fname={it => `${it.name}`}
                    form={form}
                    options={statusOptions} 
                    {...{ margin: 'none', disabled: isUpdating }}
                  />
                </Grid>
              }
              <Box display='flex' justifyContent='center' width={'100%'}>
                <ECCommonErrorBlock form={form} className={classes.commonErrorsBlock}/>
              </Box>
              <Box p={2} display='flex' justifyContent='flex-end' width={'100%'}>
                <ButtonBlock>
                  <Button color='primary' className={classes.button} onClick={handleReset} variant='contained' disabled={!form.formState.dirty}>
                    Сбросить
                  </Button>
                  <Button color='secondary' className={classes.button} type='submit' onClick={form.handleSubmit(handleSave)} variant='contained' disabled={!form.formState.dirty}>
                    Сохранить
                  </Button>
                </ButtonBlock>
              </Box>
          </Grid>
        </div>
    );
};