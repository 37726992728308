import React, { useState, useEffect, Fragment, useMemo } from 'react';
import {
  Dialog,
  Paper,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Divider,
  Link,
  makeStyles,
} from '@material-ui/core';
import { CloseModalButton } from 'components/CloseModalButton/CloseModalButton';
import { DetailsModalProps, DetailedStatusItemProps } from './types';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { periodTypes } from './types';
import { useParams } from 'react-router-dom';

import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 4, 3),
    fontSize: '16px',
    height: '480px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  buttonSuccess: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  button: {
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  listContainer: {
    height: '100%',
    overflow: 'auto',
    marginBottom: theme.spacing(3),
    padding: '8px 16px',
  },
  listItem: {
    padding: '8px',
  },
  failedTaskslistContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '5px 0 0 32px',
    background: theme.palette.grey[100],
    '& > span': {
      fontSize: '14px',
      padding: '4px 0',
    },
  },
}));

const formatTimestamp = (timestampTest: string | undefined, isTitle: boolean = false) => {
  if (!timestampTest) {
    return '';
  }
  return moment(timestampTest).format(isTitle? 'DD.MM, HH:mm' : 'HH:mm');
};

const formatPeriodType = (periodType: periodTypes) => {
  if (periodType === periodTypes.day) {
    return 'день';
  } else {
    return 'час';
  }
};

const DetailedStatusItemDropdown = React.memo(({ timestamp, id = 0, status, failedTasks, logLink }: DetailedStatusItemProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem className={classes.listItem} key={id} button onClick={handleClick}>
        <ListItemText primary={`${formatTimestamp(timestamp)}: ${status}`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Divider />
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List disablePadding>
          <ListItem disableGutters className={classes.failedTaskslistContainer}>
            <span>
              <b>logs: </b>
              <Link href={`${logLink}`}>
                {`${logLink}`}
              </Link>
            </span>
          </ListItem>
          {failedTasks?.map((failedTask, index) => (
            <Fragment key={index}>
              <ListItem disableGutters className={classes.failedTaskslistContainer}>
                <span>
                  <b>inventoryHost: </b>
                  {`${failedTask.inventoryHost}`}
                </span>
                <span>
                  <b>ansibleHost: </b>
                  {`${failedTask.ansibleHost}`}
                </span>
                <span>
                  <b>taskName: </b>
                  {`${failedTask.taskName}`}
                </span>
                <span>
                  <b>status: </b>
                  {`${failedTask.status}`}
                </span>
              </ListItem>
            </Fragment>
          ))}
        </List>
        <Divider />
      </Collapse>
    </>
  );
});

type ParamType = {
  id: string
}

const getDateAndPeriod = (id: string = '')
: {dateStart: string, periodType: periodTypes} => {
  const match = id.match(/\d_(\d*?)_(d|h)/);
  const time = match?.[1];
  const periodType = match?.[2] == 'd'? periodTypes.day : periodTypes.hour;
  const dateStart = new Date(time? Number(time) * 1000: 0).toISOString();
  return { dateStart, periodType };
};

export const DetailsModal = React.memo(
  ({ className, open, onClose, detailedHistoryData, getDetailedHistory, ...rest }: DetailsModalProps) => {
    const classes = useStyles();
    const { id } = useParams<ParamType>();

    useEffect(()=>{
      getDetailedHistory(id);
    }, [id])

    const { dateStart, periodType } = useMemo(() => getDateAndPeriod(id), [id]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm' {...rest}>
        <Paper className={classes.root}>
          <div className={classes.container}>
            <CloseModalButton onClick={onClose} />
            <Typography variant='h2' gutterBottom className={classes.title}>
              {`Детализация ${formatTimestamp(dateStart, true)} (${formatPeriodType(periodType)})`}
            </Typography>
            <Typography variant='subtitle1'>Список проверок:</Typography>
            <List className={classes.listContainer}>
              {detailedHistoryData?.checksDetailedHistory?.map((detailedStatus, index) => 
                <DetailedStatusItemDropdown {...detailedStatus} key={detailedStatus.id}/>
              )}
            </List>
            <div style={{ display: 'flex', flexGrow: 1 }} />
            <Box display='flex' justifyContent='center'>
              <Button className={classes.button} onClick={onClose} variant='contained'>
                Закрыть
              </Button>
            </Box>
          </div>
        </Paper>
      </Dialog>
    );
  }
);
