import React from 'react';
import { makeStyles } from '@material-ui/core';
import { HistoryDataInfoDataType } from './types';

const useStyles = makeStyles(theme => ({
  statusBarName: {
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    padding: '5px 5px 0px',
    fontSize: '16px',
    '& > span': {
      padding: '5px 0',
    },
  },
}));

export const StatusBarName = (infoData: HistoryDataInfoDataType | undefined = {}) => {
  const classes = useStyles();
  const { name = '' } = infoData;
  return (
    <div className={classes.statusBarName}>
      <span>
        <b>
          {name}
        </b>
      </span>
    </div>
  );
};
