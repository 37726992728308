import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';
import BarChartIcon from '@material-ui/icons/BarChart';
import BusinessIcon from '@material-ui/icons/Business';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import SecurityIcon from '@material-ui/icons/Security';

import SidebarNav from './components/SidebarNav/SidebarNav';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    marginTop: 64,
    height: 'calc(100% - 64px)',
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {},
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    // {
    //   title: 'Обзор',
    //   href: '/overview',
    //   icon: <HomeIcon />,
    // },
    // {
    //   title: 'Базовый конфиг',
    //   href: '/basic',
    //   icon: <BarChartIcon />,
    // },
    // {
    //   title: 'Продвинутый конфиг',
    //   href: '/advanced',
    //   icon: <BusinessIcon />,
    //   disabled: true,
    // },
    // {
    //   title: 'Обслуживание баз',
    //   href: '/db-maintenance',
    //   icon: <SupervisedUserCircleIcon />,
    //   disabled: true,
    // },
    {
      title: 'Статус доступности',
      href: '/status-accessibility',
      icon: <PersonOutlineIcon />,
    },
    {
      title: 'Статус конфигурации',
      href: '/status',
      icon: <PersonOutlineIcon />,
    },
    {
      title: 'Безопасность',
      href: '/security',
      icon: <SecurityIcon />,
    },
  ];

  return (
    <Drawer anchor='left' classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} pages={pages} listName={'Pages'} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
