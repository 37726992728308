import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import { StatusItemProps } from './types';

export const StatusItem = React.memo(({ status, count = 0 }: StatusItemProps) => {
  return status ? (
    <ListItem style={{ padding: '0px' }}>
      <ListItemText primary={`${status}: ${count}`} />
    </ListItem>
  ) : null;
});
