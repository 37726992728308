import React from 'react';
import clsx from 'clsx';
import { SecurityProps } from './components/types';
import { Container, makeStyles } from '@material-ui/core';
import ChangePassword from './components/ChangePassword';
import EventLog from './components/EventLog';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 720,
  },
  itemsContainer: {
    width: '100%',
    flexDirection: 'column',
    '& > *': {
      marginTop: 80,
    },
  },
}));

function Security({ className, ...rest }: SecurityProps) {
  const classes = useStyles();
  return (
    <Container maxWidth={'md'} className={clsx(classes.root, className)}>
      <div className={clsx(classes.itemsContainer, className)}>
        <ChangePassword />
        <EventLog />
      </div>
    </Container>
  );
}

export default Security;
