import React, { useMemo, useCallback, useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    Paper,
    ListItem,
    Divider,
    List,
    makeStyles
} from '@material-ui/core';
import { MuteStatus } from 'api';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import {MutedChecksListProps, MutedChecksListItemProps } from './types';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    listContainer: {
        height: '100%',
        overflow: 'hidden',
      //   overflowY: 'scroll',
      //   overflowX: 'hidden',
    },
    listItem: {
        padding: '13px 13px 13px 21px',
        display: 'flex',
        transition: 'background-color 0.3s',
        '& > div:first-child': {
            width: '65%',
            display: 'flex',
            flexDirection: 'column',
            '& > .time':{
                fontSize: '14px',
                fontWeight: '500',
                marginBottom: '3px',
            },
            '& > .info':{
                fontSize: '12px',
                color: theme.palette.action.active,
            }
        },
        '& > div:nth-child(2)': {
                width: '30%',
            display: 'flex',

                fontWeight: '500',
        },
        '& > div:nth-child(3)': {
            width: '5%',
            fontWeight: '500',
        },
        '&:hover':{
            cursor: 'pointer',
            backgroundColor: theme.palette.grey[300],
        },
        '&.selected':{
            backgroundColor: theme.palette.grey[300],
        }
    },
    listSubheader: {
        padding: 0,
        height: '52px',
    },
    listSubheaderContent: {
        display: 'flex',
        justifyContent: 'space-between', 
        alignItems: 'center',
        textAlign: 'center',
        maxHeight: '51px',
        padding: '11px 6px',
        '&> h4': {
            paddingLeft: '4px',
            color: theme.palette.text.primary,
            fontWeight: '500',
            height: 'fit-content',
        },
        '&>button': {
            padding: '3px 18px',
            height: 'fit-content',
            fontSize: '0.85rem',
            [theme.breakpoints.between('md', 'lg')]: {
                padding: '3px 15px',
            },
            [theme.breakpoints.down('md')]: {
                padding: '3px 10px',
                fontSize: '0.8rem',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '3px 2px',
                fontSize: '0.7rem',
            },
        },
    },
    listContent: {
      height: 'calc(100% - 52px)',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    containerForm: {
      display: 'flex',
      height: 'fit-content',
    },
    buttonSuccess: {
      backgroundColor: theme.palette.success.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    },
    iconButton: {
        display: 'unset',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    closeButton: {
        padding: '5px',
        color: theme.palette.text.primary,
        '&:hover': {
            color: theme.palette.error.dark,
            backgroundColor: 'rgba(184, 28, 28, 0.15)',
        }
      },
  }));

const DeleteItemButton = React.memo(({ onClick }: {onClick: () => void}) => {
    const classes = useStyles();
    return (
        <IconButton aria-label='close' className={classes.closeButton} onClick={(e) => { e.stopPropagation(); onClick()}}>
            <DeleteIcon fontSize="small"/>
        </IconButton>
    )
});

const MutedCheckListItem = React.memo(({
    id = 0,
    dateStart = "",
    duration = "",
    status = MuteStatus.Pending,
    isSelected = false,
    handleClick,
    handleDelete,
}: MutedChecksListItemProps ) => {
    const classes = useStyles();
    const formattedDateStart = useMemo(() => moment(dateStart).format('D MMMM, HH:mm'), [dateStart]);
    const formattedDuration = useMemo(() => moment.duration(duration).humanize(), [duration]);
    return (
      <React.Fragment key={id}>
        <ListItem
            className={`${classes.listItem} ${isSelected && 'selected'}`}
            onClick={handleClick}
        >
            <div>
                <span className={'time'}>{`${formattedDateStart}`}</span>
                <span className={'info'}>{`${formattedDuration}`}</span>
            </div>
            <div>
                <span>
                    {status}
                </span>
            </div>
            <DeleteItemButton onClick={handleDelete}/>
        </ListItem>
        <Divider />
      </React.Fragment>
    );
});

export const MutedChecksList = React.memo(({
    addNewMute,
    deleteMute,
    mutedChecks,
    selectMuteToEdit,
    selectedMuteId = undefined,
}: MutedChecksListProps) => {
    const classes = useStyles();
    return (
        <Paper style={{height: '100%'}} className={classes.listContainer}>
            <div className={classes.listSubheader}>
                <div className={classes.listSubheaderContent}>
                    <h4>Список</h4>
                    <Button
                        className={classes.buttonSuccess}
                        type='submit'
                        onClick={addNewMute}
                        variant='contained'
                        startIcon={ <CheckIcon fontSize="small" className={classes.iconButton}/> }
                    >
                        Добавить
                    </Button>
                </div>
                <Divider/>
            </div>
            <div className={classes.listContent}>
                <List style={{padding: 0}}>
                    {mutedChecks?.map(mute => (
                        <MutedCheckListItem
                            {...mute}
                            key={mute.id}
                            isSelected={mute.id === selectedMuteId}
                            handleClick={() => selectMuteToEdit(mute)}
                            handleDelete={() => deleteMute(mute)}/>
                    ))}
                </List>
            </div>
        </Paper>
    );
});