import React, { useCallback, useEffect, useContext, useReducer } from 'react';
import { Switch, Route, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { AppContext } from '../../AppContext';
import { DetailsModal } from './components/DetailsModal';
import { periodTypes } from 'types';
import { StatusAccessibilityProps, HistoryDataInfoDataType } from './components/types';
import { initialPageState, reducer } from './reducer';
import { StatusResultData } from 'api';
import { useGlobalLoading } from 'hooks/hooks';
import { StatusDashboard } from 'components/StatusDashboard/StatusDashboard';
import { StatusTooltipContent } from './components/StatusTooltipContent';
import { StatusBarName } from './components/StatusBarName';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 80,
  },
}));

const getDetailedHistoryId = (checkResultData: StatusResultData, statusCheckId: number): string => {
  const dateStart = checkResultData?.timestamp || '';
  console.log(dateStart);
  const epochSeconds = Math.floor(new Date(dateStart).getTime() / 1000);
  console.log(epochSeconds);
  const period = checkResultData?.period === periodTypes.day ? 'd' : 'h';
  return `${statusCheckId}_${epochSeconds}_${period}`;
};

const StatusAccessibility = ({ className }: StatusAccessibilityProps) => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const [pageState, dispatch] = useReducer(reducer, initialPageState);
  let { path } = useRouteMatch();
  const history = useHistory();
  const [isLoading, load] = useGlobalLoading();

  useEffect(() => {
    load(
      appContext.statusCheckApi.getStatusChecksHistory(periodTypes.hour).then(payload => {
        dispatch({ type: 'setHistoryData', payload: payload.data });
      })
    );
  }, []);

  const handleChangePeriodType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        dispatch({ type: 'setPeriodTypeDay' });
        appContext.setLoading(true);
        load(
          appContext.statusCheckApi.getStatusChecksHistory(periodTypes.day).then(payload => {
            dispatch({ type: 'setHistoryData', payload: payload.data });
          })
        );
      } else {
        dispatch({ type: 'setPeriodTypeHour' });
        load(
          appContext.statusCheckApi.getStatusChecksHistory(periodTypes.hour).then(payload => {
            dispatch({ type: 'setHistoryData', payload: payload.data });
          })
        );
      }
    },
    [appContext.statusCheckApi, load]
  );

  const handleClose = useCallback(() => {
    history.push(`/status-accessibility`);
  }, [history]);

  const handleOpen = useCallback(
    (statusResultData: StatusResultData, infoData: HistoryDataInfoDataType) => {
      const statusCheckId = infoData.statusCheckId;
      console.log(infoData);
      if (statusCheckId !== undefined) {
        const detailedHistoryId = getDetailedHistoryId(statusResultData, statusCheckId);
        history.push(`status-accessibility/detailed/${detailedHistoryId}`);
      }
    },
    [history]
  );

  const getDetailedHistory = useCallback(
    async (detailedHistoryId: string | undefined) => {
      if (detailedHistoryId) {
        appContext.statusCheckApi.getStatusCheckDetailedHistory(detailedHistoryId).then(payload => {
          dispatch({
            type: 'setDetailedHistory',
            payload: payload.data,
          });
        });
      }
    },
    [appContext.statusCheckApi, load]
  );

  const handleRedirect = useCallback(() => {
    appContext.history.push('muted-checks');
  }, [appContext.history]);

  return (
    <>
      <Switch>
        <Route path={`${path}/detailed/:id`}>
          <DetailsModal
            open={true}
            onClose={handleClose}
            checkDetailedHistoryData={pageState.checkDetailedHistoryData}
            getDetailedHistory={getDetailedHistory}
          />
        </Route>
        <Redirect to={`${path}`} />
      </Switch>
      <StatusDashboard<StatusResultData, HistoryDataInfoDataType>
        className={clsx(classes.root, className)}
        title={`Статус доступности`}
        handleChangePeriodType={handleChangePeriodType}
        handleRedirect={handleRedirect}
        periodType={pageState.periodType}
        renderTooltip={(resultData, infoData, closeTooltip) => (
          <StatusTooltipContent
            infoData={infoData}
            resultData={resultData}
            closeTooltip={closeTooltip}
            handleOpen={handleOpen}
          />
        )}
        titleToRender={StatusBarName}
        historyData={pageState.historyData}
      />
    </>
  );
};

export default StatusAccessibility;
