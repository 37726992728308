import React from 'react';
import { DetailedHistoryData, CheckDetailedHistoryData, StatusCheckStatusData, CheckStatusData } from 'api';
import { periodTypes } from 'types';

export interface CustomStatusData {
  ok?: number;
  changed?: number;
  warn?: number;
  failed?: number;
}

export interface CustomResultData {
  status?: CustomStatusData;
}

export interface CustomHistoryData<T extends CustomResultData, I> {
  results?: T[];
  infoData?: I;
}

export interface StatusDashboardProps<T extends CustomResultData, I> {
  className?: string;
  title?: string;
  periodType: periodTypes;
  handleChangePeriodType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRedirect: () => void;
  historyData?: CustomHistoryData<T, I>[];
  titleToRender: (infoData?: I) => React.ReactNode;
  renderTooltip: (resultData?: T, infoData?: I, closeTooltip?: () => void) => React.ReactNode;
}

export interface ConfigStatusBarProps<T extends CustomResultData, I> {
  className?: string;
  infoData?: I;
  statusItems?: T[];
  renderTooltip: (resultData?: T, infoData?: I, closeTooltip?: () => void) => React.ReactNode;
  titleToRender: (infoData?: I) => React.ReactNode;
}

export interface ConfigStatusItemProps<T extends CustomResultData, I> {
  className?: string;
  resultData?: T;
  infoData?: I;
  renderTooltip: (resultData?: T, infoData?: I, closeTooltip?: () => void) => React.ReactNode;
}

export interface StatusDashboardControllWrapProps {
  className?: string;
  title?: string;
  periodType: periodTypes;
  handleChangePeriodType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRedirect: () => void;
  children: React.ReactNode;
}

export interface ActionsBlockProps {
  className?: string;
  periodType: periodTypes;
  handleChangePeriodType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRedirect: () => void;
}

export interface DetailsModalProps {
  className?: string;
  open: boolean;
  onClose: () => void;
  detailedHistoryData?: DetailedHistoryData;
  getDetailedHistory: (detailedHistoryId?: string) => void;
}

export interface StatusItemProps {
  status: string;
  count?: number;
}

export interface DetailedStatusItemProps extends CheckDetailedHistoryData {}

export const isCheckStatusData = (status: CheckStatusData | StatusCheckStatusData): status is CheckStatusData => {
  return (status as CheckStatusData).changed !== undefined;
};
