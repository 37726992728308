import React from 'react';
import { ActionsBlockProps } from './types';
import { makeStyles } from '@material-ui/styles';
import { Switch, Typography, IconButton } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { periodTypes } from './types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: '10px',
    },
  },
}));

export const ActionsBlock = React.memo(
  ({ className, periodType, handleChangePeriodType, handleRedirect }: ActionsBlockProps) => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <div>
          <Typography component='span'>часы</Typography>
          <Switch
            checked={periodType === periodTypes.day}
            onChange={handleChangePeriodType}
            name='hoursOrDays'
            color='primary'
          />
          <Typography component='span'>дни</Typography>
        </div>
        <IconButton color="primary" onClick={handleRedirect}>
          <AssignmentIcon fontSize='large' color='action' />
        </IconButton>
      </div>
    );
  }
);
