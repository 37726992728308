import React from 'react';
import { ConfigStatusBarProps } from './types';
import { makeStyles } from '@material-ui/core/styles';
import { ConfigStatusItem } from './ConfigStatusItem';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '15px 15px',
    '& > *': {
      marginRight: '10px',
    },
  },
  configStatusBar: {
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    flexDirection: 'row',
    backgroundColor: theme.palette.action.disabledBackground,
    width: '100%',
    padding: '0 5px',
    overflowX: 'auto',
  },
  bindingBlock: {
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    padding: '5px 5px 15px',
    fontSize: '16px',
    '& > span': {
      padding: '5px 0',
    },
  },
}));

export const ConfigStatusBar = React.memo(
  ({ className, statusItems, bindingData, handleOpen, ...rest }: ConfigStatusBarProps) => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <div className={classes.bindingBlock}>
          <span>
            <b>{bindingData?.inventory}</b>
            {' <- '}
            <b>{bindingData?.playbook}</b>
            {' ('}
            <a>{bindingData?.repo}</a>
            {')'}
          </span>
        </div>
        <div className={classes.configStatusBar}>
          {statusItems?.map((statusItem, index) => (
            <ConfigStatusItem checkResultData={statusItem} bindingData={bindingData} handleOpen={handleOpen} key={index}/>
          ))}
        </div>
      </div>
    );
  }
);
