import moment from 'moment';

export const formatTimestamp = (timestamp: string | undefined, isTitle: boolean = false) => {
  if (!timestamp) {
    return '';
  }
  return moment(timestamp).format(isTitle ? 'DD.MM, HH:mm' : 'HH:mm');
};

export const JSONPrettify = (jsonString: string): string => {
  let parsedJson;
  try {
    parsedJson = JSON.parse(jsonString);
  } catch (e) {
    return jsonString;
  }
  return JSON.stringify(parsedJson, null, 2);
};
