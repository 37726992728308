import React from 'react';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {},
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface CloseModalButtonProps {
  onClick: () => void;
}

export const CloseModalButton = ({ onClick }: CloseModalButtonProps) => {
  const classes = useStyles();
  return (
    <IconButton aria-label='close' className={classes.closeButton} onClick={onClick}>
      <CloseIcon />
    </IconButton>
  );
};
