import React, { createContext } from 'react';
import axios, { AxiosInstance } from 'axios';
import {
    AccountApi,
    AuthApi,
    ConfigApi,
    ChangesApi,
    StatusCheckApi,
    ConfigCheckApi,
    MuteApi,
    ChecksApi,
    SessionInfoData,
    StateData,
} from './api';
import { isTestHost } from 'utils/utils';
// import { TopNoticeContextData } from './components/TopNotice/TopNoticeContext';

interface AppContextProps {
  apiUrl: string;
  history: any;
  // topNotice: TopNoticeContextData;
}

export interface AppContextData {
  sessionId?: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  session?: SessionInfoData;
  authApi: AuthApi;
  accountApi: AccountApi;
  configApi: ConfigApi;
  changesApi: ChangesApi;
  statusCheckApi: StatusCheckApi;
  configCheckApi: ConfigCheckApi;
  muteApi: MuteApi;
  checksApi: ChecksApi;
  axiosInstance: () => AxiosInstance;
  login: (session: SessionInfoData) => void;
  logout: () => void;
  check: () => void;
  location: string;
  history: any;
  errorHandler: (error: any) => void;
  //app state
  stateData?: StateData;
  checkAppState: () => void;
  //save changes modal
  open: boolean,
  handleSaveChanges: () => void,
  handleShowFinishChanges: (show: boolean) => void,
  handleRollbackChanges: () => void,
  handleAbortChanges: () => void,
  handleClose: () => void,
  handleOpen: () => void,
}

interface AppContextState {
  session?: SessionInfoData;
  loading: boolean;
  open: boolean;
  stateData?: StateData;
}

const AppContext = createContext<AppContextData>({
  loading: false,
  setLoading: (loading = false) => {},
  authApi: new AuthApi(),
  accountApi: new AccountApi(),
  configApi: new ConfigApi(),
  changesApi: new ChangesApi(),
  statusCheckApi: new StatusCheckApi(),
  configCheckApi: new ConfigCheckApi(),
  muteApi: new MuteApi(),
  checksApi: new ChecksApi(),
  axiosInstance: () => axios.create(),
  login: () => {},
  logout: () => {},
  check: () => {},
  location: '/',
  history: {},
  errorHandler: () => {},
  //app state
  checkAppState: () => {},
  //save changes modal
  open: false,
  handleSaveChanges: () => {},
  handleShowFinishChanges: (show) => {},
  handleRollbackChanges: () => {},
  handleAbortChanges: () => {},
  handleClose: () => {},
  handleOpen: () => {},
});

const getQueryVariable = (variable: string): string | undefined => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
};

class AppContextProvider extends React.Component<AppContextProps, AppContextState> {
  constructor(props: AppContextProps) {
    super(props);
    this.state = {
      loading: true,
      open: false,
    };
  }

  handleSaveChanges = async () => { // TODO move function to modal component
    if(this.state.session){
      const changesApi = new ChangesApi({ basePath: this.props.apiUrl, accessToken: this.state.session.sessionId });
      const configAPi = new ConfigApi({ basePath: this.props.apiUrl, accessToken: this.state.session.sessionId });
      try{
        this.setLoading(true);
        const currentConfig = (await configAPi.getConfig()).data;
        await changesApi.applyChanges(currentConfig);
        await this.checkCurrentAppState();
        this.setLoading(false);
      } catch(error){
        this.setLoading(false);
      }
    }
  }

  handleShowFinishChanges = async (show: boolean) => {
    if(this.state.session){
      const changesApi = new ChangesApi({ basePath: this.props.apiUrl, accessToken: this.state.session.sessionId });
      try{
        this.setLoading(true);
        await changesApi.modifyChangesShownState(show);
        this.setLoading(false);
      } catch(error){
        this.setLoading(false);
      }
    }
  }

  handleClose = () => {
    // if(this.state.stateData && this.state.stateData.applyOnProgress){
      this.setState({
        open: false,
      });
    // }
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  }

  componentDidMount(): void {
    const sessionFromQuery = getQueryVariable('session');
    if (sessionFromQuery) {
      localStorage.setItem('sessionId', sessionFromQuery);
    }

    this.checkSessionInfo();
  }

  componentWillUnmount(): void {
  }

  setLoading(loading: boolean): void {
    if(this.state.loading != loading){
      this.setState({
        loading: loading,
      });
    }
  }

  checkSessionInfo = () => {
    let sessionId = localStorage.getItem('sessionId') || undefined;
    if (!sessionId) {
      this.setState({
        session: undefined,
        loading: false,
      });
    } else {
      this.setState({
        loading: true,
      });
      const authApi = new AuthApi({ basePath: this.props.apiUrl, accessToken: sessionId });
      authApi
        .checkSession()
        .then(payload => {
          this.setState({
            session: payload.data,
            loading: false,
          });
          this.checkCurrentAppState();
        })
        .catch(() => {
          //localStorage.removeItem('sessionId');
          this.setState({
            session: undefined,
            loading: false,
          });
        });
    }
  };

  handleRollbackChanges = async () => {
    if(this.state.session){
      const changesApi = new ChangesApi({ basePath: this.props.apiUrl, accessToken: this.state.session.sessionId });
      const configApi = new ConfigApi({ basePath: this.props.apiUrl, accessToken: this.state.session.sessionId });
      try{
          this.setLoading(true);
          const currentConfig = (await configApi.getConfig()).data;
          await configApi.rollbackConfig(currentConfig)
          this.setLoading(false);
      } catch(error){
        this.setLoading(false);
        console.log(error);
      }
    }
  };

  handleAbortChanges = async () => {
    // const { configApi, changesApi} = this.context;
    if(this.state.session){
      const changesApi = new ChangesApi({ basePath: this.props.apiUrl, accessToken: this.state.session.sessionId });
      const configApi = new ConfigApi({ basePath: this.props.apiUrl, accessToken: this.state.session.sessionId });
      try{
          this.setLoading(true);
          const currentConfig = (await configApi.getConfig()).data;
          await changesApi.abortChanges(currentConfig);
          this.setLoading(false);
      } catch(error){
        this.setLoading(false);
        console.log(error);
      }
    }
  }

  errorHandler = (error: any) => {
    console.log('Default error handler', error);
    if (error.response && error.response.data) {
        const { config, data } = error.response;
        if (config.url.endsWith('/check-session')) {
          return;
        }
        if (data.error === 'INVALID_ACCESS_TOKEN') {
          this.logout();
        }
        if (data.error === 'RESOURCE_NOT_FOUND' && data.args.entity !== 'default group') {
          this.props.history.replace('/not-found?request=' + config.url);
        }
        if (data.error === 'SESSION_EXPIRED') {
          // this.props.topNotice.putMessage({ message: 'sessionExpired' });
        }
        if (data.error === 'INTERNAL_SERVER_ERROR') {
          isTestHost(data);
        }
        return Promise.reject(error);
    } else {
        return Promise.reject(error);
    }
  };

  axiosInstance = () => {
    const instance = axios.create();
    instance.interceptors.response.use(
      response => response,
      error => {
        return this.errorHandler(error);
      }
    );
    return instance;
  };

  login = (session: SessionInfoData) => {
    localStorage.setItem('sessionId', session.sessionId!!);
    this.setState({
      session: session,
      loading: false,
    });
    this.checkSessionInfo();
  };

  logout = () => {
    if (this.state.session) {
      const authApi = new AuthApi({ basePath: this.props.apiUrl, accessToken: this.state.session.sessionId });
      localStorage.removeItem('sessionId');
      this.setState({
        session: undefined,
        loading: false,
      });
      authApi.logout();
      this.props.history.push('/sign-in');
    }
  };

  checkCurrentAppState = () => {
    if (this.state.session) {
      this.setState({
        loading: true,
      });
      const changesApi = new ChangesApi({ basePath: this.props.apiUrl, accessToken: this.state.session.sessionId });
      changesApi.checkState()
        .then(payload => {
          if(payload.data && (payload.data.applyOnProgress || payload.data.resultShown)){
            this.setState({
              stateData: payload.data,
              loading: false,
              open: true,
            });
          } else {
            this.setState({
              stateData: payload.data,
              loading: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            stateData: undefined,
            loading: false,
          });
        });
    };
  };

  render() {
    const sessionId = this.state.session != null ? this.state.session.sessionId : undefined;
    return (
      <AppContext.Provider
        value={{
          sessionId: sessionId,
          loading: this.state.loading,
          setLoading: (loading) => this.setLoading(loading),
          location: window.location.href,
          session: this.state.session,
          authApi: new AuthApi(
            { basePath: this.props.apiUrl, accessToken: sessionId },
            this.props.apiUrl,
            this.axiosInstance()
          ),
          accountApi: new AccountApi(
            { basePath: this.props.apiUrl, accessToken: sessionId },
            this.props.apiUrl,
            this.axiosInstance()
          ),
          configApi: new ConfigApi(
            { basePath: this.props.apiUrl, accessToken: sessionId },
            this.props.apiUrl,
            this.axiosInstance()
          ),
          changesApi: new ChangesApi(
            { basePath: this.props.apiUrl, accessToken: sessionId },
            this.props.apiUrl,
            this.axiosInstance()
          ),
          configCheckApi: new ConfigCheckApi(
            { basePath: this.props.apiUrl, accessToken: sessionId },
            this.props.apiUrl,
            this.axiosInstance()
          ),
          statusCheckApi: new StatusCheckApi(
            { basePath: this.props.apiUrl, accessToken: sessionId },
            this.props.apiUrl,
            this.axiosInstance()
          ),
          muteApi: new MuteApi(
            { basePath: this.props.apiUrl, accessToken: sessionId },
            this.props.apiUrl,
            this.axiosInstance()
          ),
          checksApi: new ChecksApi(
            { basePath: this.props.apiUrl, accessToken: sessionId },
            this.props.apiUrl,
            this.axiosInstance()
          ),
          axiosInstance: this.axiosInstance,
          login: this.login,
          logout: this.logout,
          check: this.checkSessionInfo,
          history: this.props.history,
          errorHandler: this.errorHandler,
          //app state
          stateData: this.state.stateData,
          checkAppState: this.checkCurrentAppState,
          //save changes modal
          open: this.state.open,
          handleSaveChanges: this.handleSaveChanges,
          handleShowFinishChanges: this.handleShowFinishChanges,
          handleRollbackChanges: this.handleRollbackChanges,
          handleAbortChanges: this.handleAbortChanges,
          handleClose: this.handleClose,
          handleOpen: this.handleOpen,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export { AppContext, AppContextProvider };
