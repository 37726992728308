import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Dialog, Paper, Typography, TextField, Box, Button, IconButton, makeStyles } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import LinearProgress from '@material-ui/core/LinearProgress';
import { AppContext } from '../../../AppContext';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // height: '100%',
    padding: '15px 0',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  progress: {
    height: '23px',
    marginBottom: theme.spacing(2),
  },
  field: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}));

const calcProgress = (currentNumb, totalNumb) => {
  if (totalNumb === 0 || typeof totalNumb != 'number') {
    return 0;
  } else if (currentNumb > totalNumb) {
    return 100;
  }
  return Math.floor(100 * (currentNumb / totalNumb));
};

export const ApplyChangesProgress = ({totalChangesCount, appliedChangesCount, updateProgress}) => {
  const classes = useStyles();
  // const [progress, setProgress] = useState({ totalChangesCount: 0, appliedChangesCount: 0 });
  // const [finished, setFinished] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const appContext = useContext(AppContext);

  // const updateProgress = () => {
  //   appContext.changesApi.getChangesStatus()
  //       .then(payload => {
  //           const { totalChangesCount = 0, appliedChangesCount = 0, finished = false } = payload.data;
  //           setProgress({ totalChangesCount, appliedChangesCount });
  //       })
  //   .catch(appContext.errorHandler)
  // };

  useEffect(() => {
    updateProgress();
    const timer = setInterval(() => {
        updateProgress();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const currentProgress = calcProgress(appliedChangesCount, totalChangesCount);

  return (
    <div className={classes.root}>
      <Typography className={classes.field}>
        {`${appliedChangesCount} of ${totalChangesCount}`}
      </Typography>
      <LinearProgress className={classes.progress} variant='determinate' value={currentProgress} />
      <div className={classes.row}>
        <Button variant='contained' startIcon={<SaveAltIcon />}>
          Cкачать логи ansible
        </Button>
        <Button variant='contained' startIcon={<SaveAltIcon />}>
          Cкачать логи компонентов
        </Button>
      </div>
    </div>
  );
};
