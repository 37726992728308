import { createTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  topLogo: "/images/logo--white.svg",
  topIcon: "/images/favicon.ico",
  appBarColor: "primary",
  documentTitle: "CM Tools"
});

export default theme;
