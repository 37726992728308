import React, { useCallback, useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Dialog,
  Paper,
  Typography,
  TextField,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  makeStyles 
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useTheme from '@material-ui/core/styles/useTheme';
import { ApplyChangesProgress } from './components/ApplyChangesProgress';
import { ButtonBlock } from 'components/ButtonBlock/ButtonBlock';
import { AppContext } from 'AppContext';
import { CloseModalButton } from "components/CloseModalButton/CloseModalButton";

const useStyles = makeStyles(theme => ({
  root: {
    // top: `50%`,
    // left: `50%`,
    // transform: `translate(-50%, -50%)`,
    // position: 'absolute',
    // width: 800,
    padding: theme.spacing(3, 4, 3),
    fontSize: '16px',
    height: '480px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    // textAlign: 'center',
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  buttonSuccess: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  button: {
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  listContainer: {
    // maxHeight: '150px',
    height: '100%',
    overflow: 'auto',
    marginBottom: theme.spacing(3),
  },
  listItem: {
    padding: '0px 16px',
  },
}));

export const ApplyChangesModal = ({
  className,
  open,
  onClose,
  changes,
  handleSubmit,
  handleShowFinish,
  handleRollback,
  handleReject,
  stateData,
  ...rest
}) => {
  const classes = useStyles();

  const appContext = useContext(AppContext);

  const [changesList, setChangesList] = useState(null);
  const [progress, setProgress] = useState({ totalChangesCount: 0, appliedChangesCount: 0 });
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if(open){
      appContext.configApi
        .getConfigChanges()
        .then(payload => {
          setChangesList(payload.data);
        })
        .catch(appContext.errorHandler);
    }
  }, [open]);

  useEffect(() => {
    //TODO finish up when appropriate logic would be on backend (3rd state)
    // if(stateData && stateData.applyOnProgress){
    //   setApplyingState('apply on progress')
    // } else if(stateData && !stateData.applyOnProgress){
    //   setApplyingState('has changes to apply')
    // }
  }, [stateData]);

  const updateProgress = () => {
    appContext.changesApi.getChangesStatus()
        .then(payload => {
            const { totalChangesCount = 0, appliedChangesCount = 0, finished = false } = payload.data;
            setProgress({ totalChangesCount, appliedChangesCount });
            if(finished){
              setFinished(true);
              handleShowFinish(true);
              appContext.checkAppState();
            }

        })
    .catch(appContext.errorHandler)
  };

  const handleClose = async () => {
    if(applyOnProgress){
      return;
    } else if(finished || resultShown){
      await handleShowFinish(false);
    }
    onClose();
  };

  const { hasChanges, applyOnProgress, resultShown } = stateData ? stateData : {};
  const { totalChangesCount, appliedChangesCount } = progress ? progress : {}; 
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm' {...rest}>
      <Paper className={classes.root}>
        <div className={classes.container}>
          <CloseModalButton onClick={handleClose}/>
          <Typography variant='h2' gutterBottom className={classes.title}>
            Применение изменений
          </Typography>
          <Typography variant='subtitle1'>Список изменений:</Typography>
          <List className={classes.listContainer}>
            {
              changesList?.map((change, index) =>(
                  <ListItem className={classes.listItem}>
                    <ListItemText primary={`· ${change}`}/>
                  </ListItem>
              ))
            }
          </List>
          <div style={{ display: 'flex', flexGrow: '1' }}/>
          {applyOnProgress && !finished &&
            <>
              <ApplyChangesProgress
                totalChangesCount={totalChangesCount}
                appliedChangesCount={appliedChangesCount}
                updateProgress={updateProgress}
              />
              <Box display='flex' justifyContent='center'>
                <Button className={classes.button} color='secondary' type='submit' onClick={handleReject} variant='contained'>
                  Прервать
                </Button>
              </Box>
            </>
            || (finished || resultShown) &&
            <> 
              <Box display='flex' justifyContent='center'>
                <ButtonBlock>
                  <Button color='primary' className={classes.button} type='submit' onClick={handleClose} variant='contained'>
                    Ок
                  </Button>
                </ButtonBlock>
              </Box>
            </>
            ||
            <> 
              <Box display='flex' justifyContent='center'>
                <ButtonBlock>
                  <Button className={classes.buttonSuccess} type='submit' onClick={handleSubmit} variant='contained'>
                    Применить
                  </Button>
                  <Button color='secondary' type='submit' onClick={handleRollback} variant='contained'>
                    Отменить
                  </Button>
                </ButtonBlock>
              </Box>
            </>
          }
        </div>
      </Paper>
    </Dialog>
  );
};
