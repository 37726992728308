import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { AppContext } from 'AppContext';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ServicesList from './components/ServicesList';
import { ConfigData } from 'api';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  itemsContainer: {
    width: '100%',
    flexDirection: 'column',
    '& > *': {
      marginTop: 80,
    },
  },
}));

type OverviewProps = {
  className?: string;
};

function Overview({ className }: OverviewProps) {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const [servicesList, setServicesList] = useState<ConfigData>({ componentList: undefined });

  useEffect(() => {
    appContext.configApi
      .getConfig()
      .then(payload => {
        setServicesList(payload.data);
      })
      .catch(appContext.errorHandler);
  }, []);

  return (
    <Container className={clsx(classes.root, className)}>
      <div className={clsx(classes.itemsContainer, className)}>
        {servicesList && (
          <>
            <ServicesList title={'Сервисы'} serviceList={servicesList.componentList} />
            <ServicesList title={'Служебные Сервисы'} serviceList={undefined} />
          </>
        )}
      </div>
    </Container>
  );
}

export default Overview;
