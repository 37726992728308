import React, { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { ServiceItem } from './ServiceItem';
import { ComponentData } from 'api';

const useStyles = makeStyles(theme => ({
  root: {},
  navigateNextIcon: {
    marginLeft: theme.spacing(1),
  },
}));

type ServicesProps = {
  className?: string;
  title: string;
  serviceList: ComponentData[] | undefined;
};

function Services({ className, title = '', serviceList, ...rest }: ServicesProps) {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={title} />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Компонент</TableCell>
                <TableCell>Ссылка</TableCell>
                <TableCell>Статус</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceList?.map(
                  service =>
                    service.urlData && (
                      <ServiceItem
                        name={service.urlData.name}
                        link={service.urlData.domainName}
                        status={service.healthCheckStatus}
                      />
                    )
                )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box p={2} display='flex' justifyContent='flex-end'></Box>
    </Card>
  );
}

export default Services;
