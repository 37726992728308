import React from 'react';
import { ConfigStatusBar } from './components/ConfigStatusBar';
import { StatusDashboardProps } from './components/types';
import { StatusDashboardControllWrap } from './components/StatusDashboardControllWrap';

export const StatusDashboard = <T extends object, I>({
  className,
  title,
  periodType,
  handleChangePeriodType,
  handleRedirect,
  historyData,
  titleToRender,
  renderTooltip,
}: StatusDashboardProps<T, I>) => {
  return (
    <StatusDashboardControllWrap
      title={title}
      periodType={periodType}
      handleChangePeriodType={handleChangePeriodType}
      handleRedirect={handleRedirect}
    >
      {historyData?.map((historyItem, index) => (
        <ConfigStatusBar<T, I>
          key={index}
          statusItems={historyItem?.results}
          infoData={historyItem?.infoData}
          titleToRender={titleToRender}
          renderTooltip={renderTooltip}
        />
      ))}
    </StatusDashboardControllWrap>
  );
};
