import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppContext } from 'AppContext';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, auth, ...rest } = props;
  const appContext = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={matchProps => {
        if (!auth || appContext.session) {
          return (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          );
        } else {
          if (!appContext.loading && !appContext.session) {
            appContext.history.push('/sign-in');
          } else {
            return null;
          }
        }
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
};

export default RouteWithLayout;
