import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, IconButton, Badge, Tooltip } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { AppContext } from 'AppContext';
import useTheme from '@material-ui/core/styles/useTheme';
import AssignmentIcon from '@material-ui/icons/Assignment';

const getTooltipText = hasChanges => (hasChanges ? `You've got changes to apply` : `No changes to apply`);

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  button: {
    padding: '4px 15px',
  },
  paper: {
    padding: theme.spacing(1),
  },
  tooltip: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[3],
    fontSize: 14,
  },
}));

const Topbar = props => {
  const { className, ...rest } = props;
  const theme = useTheme();
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const handleSignOut = event => {
    event.preventDefault();
    appContext.logout();
  };

  const hasChanges = appContext.stateData && appContext.stateData.hasChanges;

  return (
    <AppBar {...rest} color={theme.appBarColor} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to='/' style={{ zIndex: 5 }}>
          <img alt='Logo' src={theme.topLogo} />
        </RouterLink>
        <div className={classes.flexGrow} />
          <Tooltip title={getTooltipText(hasChanges)} classes={classes}>
            <IconButton
              className={classes.signOutButton}
              color='inherit'
              onClick={() => {
                hasChanges && appContext.handleOpen();
              }}
            >
              <Badge
                badgeContent='!'
                color='secondary'
                invisible={!hasChanges}
              >
                <AssignmentIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        <IconButton className={classes.signOutButton} color='inherit' onClick={handleSignOut}>
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
