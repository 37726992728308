import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, CardHeader, Container, makeStyles } from '@material-ui/core';
import { ActionsBlock } from './ActionsBlock';
import { StatusDashboardControllWrapProps } from './types';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 80,
  },
}));

export const StatusDashboardControllWrap = ({
  className,
  title,
  periodType,
  handleChangePeriodType,
  handleRedirect,
  children,
}: StatusDashboardControllWrapProps) => {
  const classes = useStyles();

  return (
    <Container>
      <Card className={clsx(classes.root, className)}>
        <CardHeader
          title={title}
          action={
            <ActionsBlock
              periodType={periodType}
              handleChangePeriodType={handleChangePeriodType}
              handleRedirect={handleRedirect}
            />
          }
        />
        <PerfectScrollbar>
          <Box>{children}</Box>
        </PerfectScrollbar>
      </Card>
    </Container>
  );
};
