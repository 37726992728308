
export const isTestHost = (data) => {
    let isTestHost = window.location.hostname === 'localhost' || window.location.hostname.startsWith('test');
    if (isTestHost) {
        alert(`${data?.message}\nuuid: ${data?.uuid}\nmessage: ${data?.args?.message}`);
    } else {
        alert(`${data?.message} uuid: ${data?.uuid}`);
    }
};

export const isPositiveNumberFromString = (stringToParse) => {
    if(typeof stringToParse != 'string'){
        return false;
    }
    return !!stringToParse && !isNaN(stringToParse) && (parseInt(stringToParse) >= 0);
}