import React from 'react';
import { ConfigStatusBarProps, CustomResultData } from './types';
import { makeStyles } from '@material-ui/core/styles';
import { ConfigStatusItem } from './ConfigStatusItem';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '15px 15px',
    '& > *': {
      marginRight: '10px',
    },
  },
  configStatusBar: {
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    flexDirection: 'row',
    backgroundColor: theme.palette.action.disabledBackground,
    width: '100%',
    padding: '0 5px',
    overflowX: 'auto',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    padding: '5px 5px 15px',
    fontSize: '16px',
    '& > span': {
      padding: '5px 0',
    },
  },
}));

const ConfigStatusBarComponent = <T extends CustomResultData, I>({
  className,
  statusItems,
  infoData,
  renderTooltip,
  titleToRender,
  ...rest
}: ConfigStatusBarProps<T, I>) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.title}>{titleToRender(infoData)}</div>
      <div className={classes.configStatusBar}>
        {statusItems?.map((statusItem, index) => (
          <ConfigStatusItem<T, I>
            resultData={statusItem}
            infoData={infoData}
            renderTooltip={renderTooltip}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export const ConfigStatusBar = React.memo(ConfigStatusBarComponent) as typeof ConfigStatusBarComponent;
