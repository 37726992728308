import React, { useCallback, useEffect, useContext, useReducer } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Typography,
    Paper,
    makeStyles
} from '@material-ui/core';
import {
    useParams,
    useLocation,
  } from "react-router-dom";
import { AppContext } from '../../AppContext';
import { MutedChecksProps } from './components/types';
import { MutedCheckData, CheckDataType, BindingData } from 'api';
import { useForm } from 'react-hook-form';
import { MutedChecksForm } from './components/MutedChecksForm';
import { MutedChecksList } from './components/MutedChecksList';
import { useGlobalLoading } from 'hooks/hooks';
import { errorsToForm } from 'components/Forms/form_utils';
import { isPositiveNumberFromString } from 'utils/utils';
import { initialPageState, reducer } from './reducer';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 80,
    marginBottom: 40,
    height: 'calc(100% - 80px - 40px)',
    minHeight: '800px',
    maxHeight: '800px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: "30px 45px 20px",
    alignItems: 'center',
    '& > h4':{
        marginBottom:"38px",
    },
  },
  button: {
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  contentWithSidebar: {
    height: '85%',
    width: '100%',
    columnGap: '20px',
    display: 'grid',
    gridTemplateColumns: '27% 73%',
  },
}));

const formatDurationFromInput = (duration: string | undefined) => {
    if(!duration){
        return '';
    }
    const durationMatch = duration.match(/^\s*?(?:(\d*?)d)?\s*(?:(\d*?)h)?\s*(?:(\d*?)m)?\s*(?:(\d*?)s)?\s*?$/) //match inputs like 4d 16h 5m 10s
    const formattedDuration = moment.duration({
        seconds: Number(durationMatch?.[4]) || undefined,
        minute: Number(durationMatch?.[3]) || undefined,
        hours: Number(durationMatch?.[2]) || undefined,
        days: Number(durationMatch?.[1]) || undefined,
    }).toISOString();
    return formattedDuration;
};

const formatDurationToInput = (duration?: string) => {
    if(!duration){
        return '1h';
    }
    const {days = 0, hours = 0, minutes = 0, seconds = 0} = moment.duration(duration)?.['_data'];
    let fotmattedDuration = '';
    days && (fotmattedDuration += `${days}d `);
    hours && (fotmattedDuration += `${hours}h `);
    minutes && (fotmattedDuration += `${minutes}m `);
    seconds && (fotmattedDuration += `${seconds}s `);
    fotmattedDuration = fotmattedDuration.slice(0, -1);
    return fotmattedDuration;
};

export const MutedChecks = ({ className, ...rest }: MutedChecksProps) => {
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const [isLoading, load] = useGlobalLoading();
    const form = useForm<MutedCheckData>({reValidateMode: 'onSubmit'});
    const { pathname } = useLocation();
    const params = useParams<{ id?: string }>();
    const [pageState, dispatch] = useReducer(reducer, initialPageState);

    useEffect(() => {
        if(params?.id != 'add-new-mute' && !isPositiveNumberFromString(params?.id)){
            appContext.history.push(`/muted-checks/add-new-mute`);
        }
        load(
            Promise.all([
                appContext.muteApi.getMutedChecks(),
                // appContext.bindingsApi.getBindingsList()
                appContext.checksApi.getChecksList()
            ])
            .then(payloads => {
                const mutedChecks = payloads[0].data.mutedChecks;
                const checks = payloads[1].data?.checks;
                if(params?.id == 'add-new-mute'){
                    dispatch({
                        type: 'addNewMutePageInit',
                        payload: { mutedChecks, checks }
                    });
                } else {
                    const selectedMute = mutedChecks?.find(mute => mute.id == params?.id);
                    if(!selectedMute){
                        appContext.history.push(`/muted-checks/add-new-mute`);
                    }
                    dispatch({
                        type: 'selectedMutePageInit',
                        payload: {
                            mutedChecks,
                            checks,
                            selectedMute
                        }
                    });
                }
            }));
    }, []);

    useEffect(() => {
        reset();
    }, [pageState.selectedMute]);

    const addNewMute = useCallback(() => {
        if(!pathname.includes('/add-new-mute')) {
            appContext.history.push(`/muted-checks/add-new-mute`);
            dispatch({
                type: 'setSelectedMute',
                payload: undefined
            });
        }
    }, [appContext.history, pathname])

    const saveMute = async (mute: MutedCheckData) => {
        const id = pageState.selectedMute?.id;
        appContext.setLoading(true);
        try{ 
            if(id) {
                mute = {...mute, id, duration: formatDurationFromInput(mute?.duration)};
                const { data: selectedMute } = await appContext.muteApi.updateMutedCheck(id, mute);
                const payload = await appContext.muteApi.getMutedChecks();
                dispatch({
                    type: 'saveNewMute',
                    payload: {selectedMute: selectedMute, mutedChecks: payload.data.mutedChecks}
                });
            } else {
                mute = {...mute, id, duration: formatDurationFromInput(mute?.duration), status: undefined};
                mute = (await appContext.muteApi.createMutedChecks(mute)).data;
                const payload = await appContext.muteApi.getMutedChecks();
                dispatch({
                    type: 'saveNewMute',
                    payload: {selectedMute: mute, mutedChecks: payload.data.mutedChecks }
                });
                appContext.history.push(`/muted-checks/${mute?.id}`);
            }
        } catch(error) {
            errorsToForm(form)(error)
        }
        appContext.setLoading(false);
    };

    const deleteMute = useCallback(async (mute: MutedCheckData) => {
        if(mute.id){
            appContext.setLoading(true);
            try{
                await appContext.muteApi.deleteMutedCheck(mute.id);
                const payload = await appContext.muteApi.getMutedChecks();
                const mutedChecks = payload.data.mutedChecks;
                if(pageState.selectedMute?.id != mute.id){
                    dispatch({
                        type: 'deleteMute',
                        payload: mutedChecks
                    });
                } else {
                    const selectedMute = mutedChecks?.find(mute => mute.id == params?.id);
                    dispatch({
                        type: 'deleteSelectedMute',
                        payload: {
                            mutedChecks,
                            selectedMute
                        }
                    });
                }
            } catch(error) {}
            appContext.setLoading(false);
        }
    }, [appContext.muteApi, pageState.selectedMute]);

    const selectMuteToEdit = useCallback((mute: MutedCheckData) => {
        dispatch({
            type: 'setSelectedMute',
            payload: mute
        });
        appContext.history.push(`/muted-checks/${mute.id}`);
    }, [appContext.history]);

    const reset = () => {
        const { selectedMute, checks } = pageState;
        const duration = formatDurationToInput(selectedMute?.duration);
        const selectedChecks = checks?.filter(check => selectedMute?.checks?.some((selectedCheck) => check.id === selectedCheck.id)) || [];
        form.reset({dateStart: '', comment: '', ...selectedMute, duration, checks: selectedChecks } || {});
    };

    return (
        <Container>
            <Paper className={clsx(classes.root, className)}>
                <Typography variant='h4'>
                    Список заглушенных проверок
                </Typography>
                <div className={classes.contentWithSidebar}>
                    <MutedChecksList
                        addNewMute={addNewMute}
                        deleteMute={deleteMute}
                        mutedChecks={pageState.mutedChecks}
                        selectMuteToEdit={selectMuteToEdit}
                        selectedMuteId={pageState.selectedMute?.id}
                    />
                    <MutedChecksForm
                        form={form}
                        checks={pageState.checks}
                        handleSave={saveMute}
                        handleReset={reset}
                        isUpdating={!!pageState.selectedMute}
                        updatingMuteOldDate={pageState.selectedMute?.dateStart}
                    />
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }} />
                <Box display='flex' justifyContent='center'>
                    <RouterLink to="/status">
                        <Button className={classes.button} variant='contained'>
                            Закрыть
                        </Button>                   
                    </RouterLink>
                </Box>
            </Paper>
        </Container>
    );
};
