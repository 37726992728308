import React, { useCallback, useEffect, useContext, useReducer } from 'react';
import { Switch, Route, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, CardHeader, Container, makeStyles } from '@material-ui/core';
import { AppContext } from '../../AppContext';
import { ActionsBlock } from './components/ActionsBlock';
import { ConfigStatusBar } from './components/ConfigStatusBar';
import { DetailsModal } from './components/DetailsModal';
import { StatusProps, periodTypes } from './components/types';
import { initialPageState, reducer } from './reducer';
import { CheckResultData } from 'api';
import { useGlobalLoading } from 'hooks/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 80,
  },
}));

const getDetailedHistoryId = (checkResultData: CheckResultData, bindingId: Number): string => {
  const dateStart = checkResultData?.dateStart || '';
  const epochSeconds = new Date(dateStart).getTime()/1000;
  const period = checkResultData?.period === periodTypes.day? 'd' : 'h';
  return `${bindingId}_${epochSeconds}_${period}`;
}

const Status = ({ className }: StatusProps) => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const [pageState, dispatch] = useReducer(reducer, initialPageState);
  let { path } = useRouteMatch();
  const history = useHistory();
  const [isLoading, load] = useGlobalLoading();

  useEffect(() => {
    load(appContext.configCheckApi.getHistory(periodTypes.hour)
      .then(payload => {
        dispatch({ type: 'setHistoryData', payload: payload.data });
      })
    );
  }, []);

  const handleChangePeriodType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        dispatch({ type: 'setPeriodTypeDay' });
        appContext.setLoading(true);
        load(appContext.configCheckApi.getHistory(periodTypes.day)
          .then(payload => {
            dispatch({ type: 'setHistoryData', payload: payload.data });
          })
        );
      } else {
        dispatch({ type: 'setPeriodTypeHour' });
        load(appContext.configCheckApi.getHistory(periodTypes.hour)
          .then(payload => {
            dispatch({ type: 'setHistoryData', payload: payload.data });
          })
        );
      }
    },
    [appContext.configCheckApi, load]
  );

  const handleClose = useCallback(() => {
    history.push(`/status`);
  }, [history]);

  const handleOpen = useCallback((checkResultData: CheckResultData, id: Number = 0) => {
    const detailedHistoryId = getDetailedHistoryId(checkResultData, id );
    history.push(`status/detailed/${detailedHistoryId}`);
  }, [history])

  const getDetailedHistory = useCallback(
    (detailedHistoryId: string | undefined) => {
      if (detailedHistoryId) {
        //TODO loading spinner in modal
        load(appContext.configCheckApi.getDetailedHistory(detailedHistoryId)
          .then(payload => {
            dispatch({
              type: 'setDetailedHistory',
              payload:  payload.data
            });
          })
        );
      }
    },
    [appContext.configCheckApi, load]
  );

  const handleRedirect = useCallback(()=>{
    appContext.history.push('muted-checks');
  }, [appContext.history])

  const envToRender = pageState.env? `(${pageState.env})` : '';

  return (
    <Container>
        <Switch>
          <Route path={`${path}/detailed/:id`}>
            <DetailsModal
              open={true}
              onClose={handleClose}
              detailedHistoryData={pageState.detailedHistoryData}
              getDetailedHistory={getDetailedHistory}
            />
          </Route>
          <Redirect to={`${path}`} />
        </Switch>
      <Card className={clsx(classes.root, className)}>
        <CardHeader
          title={`Статус по конфигурации ${envToRender}`}
          action={<ActionsBlock periodType={pageState.periodType} handleChangePeriodType={handleChangePeriodType} handleRedirect={handleRedirect}/>}
        />
        <PerfectScrollbar>
          <Box>
            {pageState.historyData?.map((checkHistoryData, index) => (
              <ConfigStatusBar
                key={index}
                statusItems={checkHistoryData?.results}
                bindingData={checkHistoryData?.binding}
                handleOpen={handleOpen}
              />
            ))}
          </Box>
        </PerfectScrollbar>
      </Card>
    </Container>
  );
};

export default Status;
