import { useState, useCallback, useContext } from 'react';
import { AppContext } from './../AppContext';

type LoadCallback = <T>(promise: Promise<T>) => Promise<T>;

export const useLoading = (): any => {
  const [isLoading, setLoading] = useState(false);

  const load: LoadCallback = useCallback(promise => {
    setLoading(true);
    return promise.finally(() => setLoading(false));
  }, []);

  return [isLoading, load];
};

export const useGlobalLoading = (): any => {
    const appContext = useContext(AppContext);
    const isLoading = appContext.loading;
    const load: LoadCallback = useCallback(promise => {
        appContext.setLoading(true);
        return promise.finally(() => appContext.setLoading(false));
        }, [])
    return [isLoading, load];
}