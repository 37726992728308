import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, List } from '@material-ui/core';
import { StatusItem } from 'components/StatusDashboard/components/StatusItem';
import { StatusTooltipContentProps } from './types';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  tooltipContent: {
    padding: '10px 20px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listContainer: {
    height: '100%',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '0',
    marginBottom: '10px',
  },
  linkButton: {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    color: '#069',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const formatTimestamp = (tiemstampTest: string | undefined) => {
  if (!tiemstampTest) {
    return '';
  } else {
    return moment(tiemstampTest).format('D MMMM, HH:mm');
  }
};

export const StatusTooltipContent = ({ infoData, resultData, handleOpen, closeTooltip }: StatusTooltipContentProps) => {
  const classes = useStyles();
  const status = resultData?.status || { ok: 0, warn: 0, failed: 0 };

  const handleOpenDetails = useCallback(() => {
    if (resultData && infoData) {
      closeTooltip && closeTooltip();
      handleOpen(resultData, infoData);
    }
  }, []);

  return (
    <div className={classes.tooltipContent}>
      <Typography variant='h5' gutterBottom>
        {formatTimestamp(resultData?.timestamp)}
      </Typography>
      <List className={classes.listContainer}>
        <StatusItem status={'ok'} count={status?.ok} />
        <StatusItem status={'warn'} count={status?.warn} />
        <StatusItem status={'failed'} count={status?.failed} />
      </List>
      <button className={classes.linkButton} onClick={handleOpenDetails}>
        подробнее
      </button>
    </div>
  );
};
