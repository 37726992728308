import React, { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  Paper,
  Typography,
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ECTextField } from '../../../components/Forms/ECTextField';
import { SSLModalProps, SSLModalData } from './types';
import { CloseModalButton } from 'components/CloseModalButton/CloseModalButton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 4, 3),
    fontSize: '16px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  field: {
    marginBottom: theme.spacing(2),
  },
}));

export const SSLModal = ({
  className,
  open,
  onClose,
  id,
  sslData = { privateKey: '', publicKey: '' },
  handleSubmit,
  ...rest
}: SSLModalProps) => {
  const classes = useStyles();
  const form = useForm<SSLModalData>();
  const { publicKey = '', privateKey = '' } = sslData;

  useEffect(() => {
    form.reset({ publicKey, privateKey });
  }, [sslData]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm' {...rest}>
      <Paper className={classes.root}>
        <div className={classes.container}>
          <CloseModalButton onClick={onClose}/>
          <Typography variant='h2' gutterBottom className={classes.field}>
            SSL ключи
          </Typography>
            <ECTextField
              // className={classes.field}
              label='Закрытый ключ'
              name={`privateKey`}
              initial={{ privateKey }}
              form={form}
              // margin={'none'}
              {...{ className: classes.field, margin: 'none' }}
            />
            <ECTextField
              // className={classes.field}
              label='Открытый ключ'
              name={`publicKey`}
              initial={{ publicKey }}
              form={form}
              // margin={'none'}
              {...{ className: classes.field, margin: 'none' }}
            />
          <Box display='flex' justifyContent='flex-end'>
            <Button color='secondary' type='submit' onClick={() => handleSubmit(form.getValues())} variant='contained'>
              Прикрепить ключи
            </Button>
          </Box>
        </div>
      </Paper>
    </Dialog>
  );
};
