import React from 'react';
import { CheckResultData, BindingData, DetailedHistoryData, CheckDetailedHistoryData } from 'api';

export interface StatusProps {
  className?: string;
}

export interface ActionsBlockProps {
  className?: string;
  periodType: periodTypes;
  handleChangePeriodType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRedirect: () => void;
}

export interface ConfigStatusBarProps {
  className?: string;
  bindingData?: BindingData;
  statusItems?: CheckResultData[];
  handleOpen: (checkResultData: CheckResultData, id: Number) => void;
}

export interface ConfigStatusItemProps {
  className?: string;
  checkResultData: CheckResultData;
  bindingData?: BindingData;
  handleOpen: (checkResultData: CheckResultData, id: Number) => void;
}

export interface DetailsModalProps {
  className?: string;
  open: boolean;
  onClose: () => void;
  detailedHistoryData?: DetailedHistoryData;
  getDetailedHistory: (detailedHistoryId?: string) => void;
}

export interface StatusItemProps {
  status: string;
  count?: number;
}

export interface DetailedStatusItemProps extends CheckDetailedHistoryData {}

export enum periodTypes {
  hour = 'hour',
  day = 'day',
}
