import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { TableCell, TableRow, Chip, makeStyles } from '@material-ui/core';

type ServiceItemProps = {
  name: string | undefined;
  link: string | undefined;
  status: boolean | undefined;
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.success.light,
    color: 'white',
    padding: '0 10px',
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.fontSize,
  },
  chipError: {
    backgroundColor: theme.palette.error.light,
  },
}));

export const ServiceItem = ({ name = '', link = '', status = false }: ServiceItemProps) => {
  const classes = useStyles();

  const renderStatus = (): ReactNode =>
    status ? (
      <Chip className={classes.root} label={'OK'} />
    ) : (
      <Chip className={clsx(classes.root, classes.chipError)} label={'NOT OK'} />
    );

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{link}</TableCell>
      <TableCell>{renderStatus()}</TableCell>
    </TableRow>
  );
};
