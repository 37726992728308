import React, { useState, useEffect } from 'react';
import { IconButton, TableCell, TableRow, makeStyles } from '@material-ui/core';
import { ECTextField } from '../../../components/Forms/ECTextField';
import { ECCheckboxField } from '../../../components/Forms/ECCheckboxField';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { SSLModal } from './SSLModal';
import { Controller } from 'react-hook-form';
import { ItemConfigProps, SSLModalData } from './types';

const useStyles = makeStyles(theme => ({
  root: {
    animation: 'fadeIn 0.2s',
  },
  attachment: {
    color: theme.palette.success.main,
  },
}));

export const ItemConfig = ({ className, id, form, initial, name, ...rest }: ItemConfigProps) => {
  const classes = useStyles();
  const watchSSLActive : boolean = form.watch(`configs[${id}].sslData.active`);
  const watchSSLData : SSLModalData = form.watch(`configs[${id}].sslData`);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const doesHasAttachment = () => (watchSSLData ? watchSSLData.privateKey || watchSSLData.publicKey : false);

  const handleSubmitModal = ({ privateKey, publicKey }: SSLModalData) => {
    form.setValue(`configs[${id}].sslData.privateKey`, privateKey);
    form.setValue(`configs[${id}].sslData.publicKey`, publicKey);
    handleClose();
  };

  useEffect(() => {
    if (!watchSSLActive) {
      form.setValue(`configs[${id}].sslData.privateKey`, undefined);
      form.setValue(`configs[${id}].sslData.publicKey`, undefined);
    }
  }, [watchSSLActive]);
  return (
    <>
      <SSLModal
        open={open}
        id={id}
        sslData={watchSSLData}
        onClose={handleClose}
        handleSubmit={handleSubmitModal}
      />
      <TableRow className={classes.root}>
        <TableCell> {initial.name} </TableCell>
        <input type='hidden' name={`configs[${id}].name`} ref={form.register} />
        <TableCell>
          <Controller
            as={ECTextField}
            label='Доменное имя'
            name={`configs[${id}].domainName`}
            control={form.control}
            defaultValue={initial.domainName}
            form={form}
          />
        </TableCell>
        <TableCell>
          <ECCheckboxField
            name={`configs[${id}].sslData.active`}
            defaultValue={initial.sslData && initial.sslData.active}
            form={form}
          />
          <input type='hidden' name={`configs[${id}].sslData.privateKey`} ref={form.register} />
          <input type='hidden' name={`configs[${id}].sslData.publicKey`} ref={form.register} />
          <IconButton
            color='default'
            onClick={handleOpen}
            disabled={!watchSSLActive}
            className={doesHasAttachment() ? classes.attachment : undefined}
          >
            <AttachFileIcon fontSize='large' />
          </IconButton>
        </TableCell>
        <TableCell>
          <ECCheckboxField name={`configs[${id}].encryption`} defaultValue={initial.encryption} form={form} />
        </TableCell>
      </TableRow>
      </>
  );
};
