import React, { useState, useRef } from 'react';
import { ConfigStatusItemProps, StatusItemProps } from './types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Popover, List, ListItem, ListItemText } from '@material-ui/core';
import { CheckStatusData } from 'api';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '25px 0',
    display: 'flex',
    flexGrow: 1,
    margin: '0 5px',
    minWidth: '10px',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      filter: 'brightness(1.1)',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 2px',
    },
  },
  popover: {
    width: '230px',
    pointerEvents: "auto",
  },
  popoverItem: {
    pointerEvents: "none",
  },
  popoverContent: {
    padding: '10px 20px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listContainer: {
    height: '100%',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '0',
    marginBottom: '10px',
  },
  listItem: {
    padding: '0px',
  },
  linkButton: {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    color: '#069',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const calcColor = ({ ok: okCount = 0, changed: changedCount = 0, failed: failedCount = 0 }: CheckStatusData) => {
  const okColor: number = 115;
  const changedColor: number = 54;
  const failedColor: number = 0;
  const sum = okCount + changedCount + failedCount;
  if (!sum) {
    return `hsl(0, 0%, 98%)`;
  }
  const changeColorWeight = changedCount && Math.max(0.2, changedCount/sum);
  const failedColorWeight = failedCount && Math.max(0.3, failedCount/sum);
  const okColorWeight = 1 -changeColorWeight - failedColorWeight;
  const colorHue = okColor * okColorWeight + changedColor * changeColorWeight + failedColor * failedColorWeight;
  return `hsl(${colorHue}, 65%, 50%)`;
};

const formatTimestamp = (tiemstampTest: string | undefined) => {
  if (!tiemstampTest) {
    return '';
  } else {
    return moment(tiemstampTest).format('D MMMM, HH:mm');
  }
};

const StatusItem = React.memo(({ status, count = 0 }: StatusItemProps) => {
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem}>
      <ListItemText primary={`${status}: ${count}`} />
    </ListItem>
  );
});

export const ConfigStatusItem = React.memo(({ checkResultData, bindingData, handleOpen, ...rest }: ConfigStatusItemProps) => {
  const classes = useStyles();
  const status = checkResultData?.status || { ok: 0, changed: 0, failed: 0 };
  const itemColor = calcColor(status);
  
  const anchorEl = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const handleOpenDetails = () => {
    if(checkResultData?.dateStart && typeof(bindingData?.id) === "number"){
      handlePopoverClose();
      handleOpen(checkResultData, bindingData?.id);
    }
  };

  return (
    <>
      <div
        className={classes.root}
        style={{ backgroundColor: itemColor }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        ref={anchorEl}/>
      <Popover
        id="simple-popover"
        className={classes.popoverItem}
        disableRestoreFocus
        open={open}
        anchorEl={anchorEl.current}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'center',
        }}
        PaperProps={{
          className: classes.popover,
          onMouseEnter: handlePopoverOpen,
          onMouseLeave: handlePopoverClose,
        }}
      >
        <div className={classes.popoverContent}>
          <Typography variant='h5' gutterBottom>
            {formatTimestamp(checkResultData?.dateStart)}
          </Typography>
          <List className={classes.listContainer}>
            <StatusItem status={'ok'} count={status.ok} />
            <StatusItem status={'changed'} count={status.changed} />
            <StatusItem status={'failed'} count={status.failed} />
          </List>
          <button className={classes.linkButton} onClick={handleOpenDetails}>
            подробнее
          </button>
        </div>
      </Popover>
    </>
  );
});
