import React, { useState, useRef } from 'react';
import { ConfigStatusItemProps, isCheckStatusData, CustomResultData } from './types';
import { makeStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';
import { StatusCheckStatusData, CheckStatusData } from 'api';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '25px 0',
    display: 'flex',
    flexGrow: 1,
    margin: '0 5px',
    minWidth: '10px',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      filter: 'brightness(1.1)',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 2px',
    },
  },
  popover: {
    width: '230px',
    pointerEvents: 'auto',
  },
  popoverItem: {
    pointerEvents: 'none',
  },
  popoverContent: {
    padding: '10px 20px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItem: {
    padding: '0px',
  },
}));

const calcColor = (status: CheckStatusData | StatusCheckStatusData) => {
  const {
    ok: okCount = 0,
    failed: failedCount = 0,
  } = status;
  let changedCount = 0;
  if (isCheckStatusData(status)) {
    changedCount = status?.changed || 0;
  } else {
    changedCount = status?.warn || 0;
  }
  const okColor: number = 115;
  const changedColor: number = 54;
  const failedColor: number = 0;
  const sum = okCount + changedCount + failedCount;
  if (!sum) {
    return `hsl(0, 0%, 98%)`;
  }
  const changeColorWeight = changedCount && Math.max(0.2, changedCount / sum);
  const failedColorWeight = failedCount && Math.max(0.3, failedCount / sum);
  const okColorWeight = 1 - changeColorWeight - failedColorWeight;
  const colorHue = okColor * okColorWeight + changedColor * changeColorWeight + failedColor * failedColorWeight;
  return `hsl(${colorHue}, 65%, 50%)`;
};

const ConfigStatusItemComponent = <T extends CustomResultData, I>({
  resultData,
  infoData,
  renderTooltip,
}: ConfigStatusItemProps<T, I>) => {
  const classes = useStyles();
  const status = resultData?.status || { ok: 0, changed: 0, failed: 0 };
  const itemColor = calcColor(status);

  const anchorEl = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={classes.root}
        style={{ backgroundColor: itemColor }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        ref={anchorEl}
      />
      <Popover
        id='simple-popover'
        className={classes.popoverItem}
        disableRestoreFocus
        open={open}
        anchorEl={anchorEl.current}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'center',
        }}
        PaperProps={{
          className: classes.popover,
          onMouseEnter: handlePopoverOpen,
          onMouseLeave: handlePopoverClose,
        }}
      >
        <div className={classes.popoverContent}>{renderTooltip(resultData, infoData, handlePopoverClose)}</div>
      </Popover>
    </>
  );
};

export const ConfigStatusItem = React.memo(ConfigStatusItemComponent) as typeof ConfigStatusItemComponent;
