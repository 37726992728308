import { Reducer } from 'react';
import { periodTypes } from 'types';
import { StatusHistoryData, DetailedStatusHistoryData, StatusCheckDetailedHistoryData, StatusResultData } from 'api';
import { CustomHistoryData } from 'components/StatusDashboard/components/types';
import { HistoryDataInfoDataType } from './components/types';

type PageState = {
  checkDetailedHistoryData?: StatusCheckDetailedHistoryData[];
  openModal: boolean;
  dateStartModal?: string;
  periodType: periodTypes;
  historyData?: CustomHistoryData<StatusResultData, HistoryDataInfoDataType>[];
  env?: string;
};

type PageAction =
  | { type: 'setDetailedHistory'; payload?: DetailedStatusHistoryData }
  | { type: 'setPeriodTypeDay' }
  | { type: 'setPeriodTypeHour' }
  | { type: 'setHistoryData'; payload?: StatusHistoryData };

type PageReducer = Reducer<PageState, PageAction>;

export const initialPageState: PageState = {
  checkDetailedHistoryData: undefined,
  openModal: false,
  dateStartModal: undefined,
  periodType: periodTypes.hour,
  historyData: undefined,
};

export const reducer: PageReducer = (state, action) => {
  switch (action.type) {
    case 'setDetailedHistory':
      const checkDetailedHistoryData = action.payload?.checksDetailedHistory;
      return { ...state, checkDetailedHistoryData };
    case 'setPeriodTypeDay':
      return { ...state, periodType: periodTypes.day };
    case 'setPeriodTypeHour':
      return { ...state, periodType: periodTypes.hour };
    case 'setHistoryData':
      const historyData = action.payload?.historyData?.map(data => ({
        results: data.results,
        infoData: {
          name: data.name,
          statusCheckId: data.statusCheckId,
        },
      }));
      return { ...state, historyData };
    default:
      throw new Error();
  }
};
