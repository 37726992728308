import React, { useEffect, useMemo } from 'react';
import { Dialog, Paper, Typography, Box, Button, List, makeStyles } from '@material-ui/core';
import { CloseModalButton } from 'components/CloseModalButton/CloseModalButton';
import { DetailsModalProps, CustomCheckDetailedHistoryData, ParamType } from './components/types';
import { periodTypes } from 'types';
import { useParams } from 'react-router-dom';
import { useLoading } from 'hooks';
import LoadingSpinner from 'components/LoadingSpinner';

import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 4, 3),
    fontSize: '16px',
    height: '480px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  button: {
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  listContainer: {
    height: '100%',
    overflow: 'auto',
    marginBottom: theme.spacing(3),
    padding: '8px 16px',
  },
  listItem: {
    padding: '8px',
  },
}));

const formatTimestamp = (timestampTest: string | undefined, isTitle: boolean = false) => {
  if (!timestampTest) {
    return '';
  }
  return moment(timestampTest).format(isTitle ? 'DD.MM, HH:mm' : 'HH:mm');
};

const formatPeriodType = (periodType: periodTypes) => {
  if (periodType === periodTypes.day) {
    return 'день';
  } else {
    return 'час';
  }
};

const getDateAndPeriod = (id: string = ''): { dateStart: string; periodType: periodTypes } => {
  const match = id.match(/\d_(\d*?)_(d|h)/);
  const time = match?.[1];
  const periodType = match?.[2] === 'd' ? periodTypes.day : periodTypes.hour;
  const dateStart = new Date(time ? Number(time) * 1000 : 0).toISOString();
  return { dateStart, periodType };
};

export const StatusDetailedModalComponent = <T extends CustomCheckDetailedHistoryData>({
  className,
  open,
  onClose,
  checkDetailedHistoryData,
  getDetailedHistory,
  renderStatusItem,
  ...rest
}: DetailsModalProps<T>) => {
  const classes = useStyles();
  const { id } = useParams<ParamType>();
  const [isLoading, load] = useLoading();

  useEffect(() => {
    load(getDetailedHistory(id));
  }, [id]);

  const { dateStart, periodType } = useMemo(() => getDateAndPeriod(id), [id]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm' {...rest}>
      <Paper className={classes.root}>
        {isLoading ? <LoadingSpinner /> : null}
        <div className={classes.container}>
          <CloseModalButton onClick={onClose} />
          <Typography variant='h2' gutterBottom className={classes.title}>
            {`Детализация ${formatTimestamp(dateStart, true)} (${formatPeriodType(periodType)})`}
          </Typography>
          <Typography variant='subtitle1'>Список проверок:</Typography>
          <List className={classes.listContainer}>
            {checkDetailedHistoryData?.map((detailedStatus, index) =>
              renderStatusItem(detailedStatus, detailedStatus?.id)
            )}
          </List>
          <div style={{ display: 'flex', flexGrow: 1 }} />
          <Box display='flex' justifyContent='center'>
            <Button className={classes.button} onClick={onClose} variant='contained'>
              Закрыть
            </Button>
          </Box>
        </div>
      </Paper>
    </Dialog>
  );
};

export const StatusDetailedModal = React.memo(StatusDetailedModalComponent) as typeof StatusDetailedModalComponent;
