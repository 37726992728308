// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * CmTools API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivitiesStatusesData
 */
export interface ActivitiesStatusesData {
    /**
     * 
     * @type {Array<ActivityStatusData>}
     * @memberof ActivitiesStatusesData
     */
    activityStatusList?: Array<ActivityStatusData>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ActivityStatus {
    Pending = 'pending',
    Queued = 'queued',
    InProgress = 'inProgress',
    Muted = 'muted'
}

/**
 * 
 * @export
 * @interface ActivityStatusData
 */
export interface ActivityStatusData {
    /**
     * 
     * @type {BindingData}
     * @memberof ActivityStatusData
     */
    binding?: BindingData;
    /**
     * 
     * @type {ActivityStatus}
     * @memberof ActivityStatusData
     */
    status?: ActivityStatus;
}
/**
 * 
 * @export
 * @interface BindingData
 */
export interface BindingData {
    /**
     * 
     * @type {number}
     * @memberof BindingData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BindingData
     */
    repo?: string;
    /**
     * 
     * @type {string}
     * @memberof BindingData
     */
    playbook?: string;
    /**
     * 
     * @type {string}
     * @memberof BindingData
     */
    inventory?: string;
    /**
     * 
     * @type {string}
     * @memberof BindingData
     */
    branch?: string;
}
/**
 * 
 * @export
 * @interface CheckData
 */
export interface CheckData {
    /**
     * 
     * @type {CheckDataType}
     * @memberof CheckData
     */
    type?: CheckDataType;
    /**
     * 
     * @type {string}
     * @memberof CheckData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckData
     */
    displayName?: string;
    /**
     * 
     * @type {BindingData}
     * @memberof CheckData
     */
    configBinding?: BindingData;
    /**
     * 
     * @type {StatusCheckData}
     * @memberof CheckData
     */
    statusCheck?: StatusCheckData;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CheckDataType {
    ConfigBinding = 'configBinding',
    StatusCheck = 'statusCheck'
}

/**
 * 
 * @export
 * @interface CheckDetailedHistoryData
 */
export interface CheckDetailedHistoryData {
    /**
     * 
     * @type {string}
     * @memberof CheckDetailedHistoryData
     */
    timestamp?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckDetailedHistoryData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckDetailedHistoryData
     */
    logLink?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckDetailedHistoryData
     */
    status?: string;
    /**
     * 
     * @type {Array<FailedTasksData>}
     * @memberof CheckDetailedHistoryData
     */
    failedTasks?: Array<FailedTasksData>;
}
/**
 * 
 * @export
 * @interface CheckHistoryData
 */
export interface CheckHistoryData {
    /**
     * 
     * @type {BindingData}
     * @memberof CheckHistoryData
     */
    binding?: BindingData;
    /**
     * 
     * @type {Array<CheckResultData>}
     * @memberof CheckHistoryData
     */
    results?: Array<CheckResultData>;
}
/**
 * 
 * @export
 * @interface CheckResultData
 */
export interface CheckResultData {
    /**
     * 
     * @type {string}
     * @memberof CheckResultData
     */
    dateStart?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckResultData
     */
    period?: string;
    /**
     * 
     * @type {CheckStatusData}
     * @memberof CheckResultData
     */
    status?: CheckStatusData;
}
/**
 * 
 * @export
 * @interface CheckStatusData
 */
export interface CheckStatusData {
    /**
     * 
     * @type {number}
     * @memberof CheckStatusData
     */
    ok?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckStatusData
     */
    changed?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckStatusData
     */
    failed?: number;
}
/**
 * 
 * @export
 * @interface CheckStatusHistoryData
 */
export interface CheckStatusHistoryData {
    /**
     * 
     * @type {number}
     * @memberof CheckStatusHistoryData
     */
    statusCheckId?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckStatusHistoryData
     */
    name?: string;
    /**
     * 
     * @type {Array<StatusResultData>}
     * @memberof CheckStatusHistoryData
     */
    results?: Array<StatusResultData>;
}
/**
 * 
 * @export
 * @interface ChecksData
 */
export interface ChecksData {
    /**
     * 
     * @type {Array<CheckData>}
     * @memberof ChecksData
     */
    checks?: Array<CheckData>;
}
/**
 * 
 * @export
 * @interface ComponentData
 */
export interface ComponentData {
    /**
     * 
     * @type {UrlData}
     * @memberof ComponentData
     */
    urlData?: UrlData;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentData
     */
    healthCheckStatus?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ComponentData
     */
    healthCheckUrl?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ConcurrencyMode {
    Serial = 'serial',
    Parallel = 'parallel',
    Groups = 'groups'
}

/**
 * 
 * @export
 * @interface ConfigData
 */
export interface ConfigData {
    /**
     * 
     * @type {number}
     * @memberof ConfigData
     */
    configId?: number;
    /**
     * 
     * @type {Array<ComponentData>}
     * @memberof ConfigData
     */
    componentList?: Array<ComponentData>;
}
/**
 * 
 * @export
 * @interface DetailedHistoryData
 */
export interface DetailedHistoryData {
    /**
     * 
     * @type {Array<CheckDetailedHistoryData>}
     * @memberof DetailedHistoryData
     */
    checksDetailedHistory?: Array<CheckDetailedHistoryData>;
}
/**
 * 
 * @export
 * @interface DetailedStatusHistoryData
 */
export interface DetailedStatusHistoryData {
    /**
     * 
     * @type {string}
     * @memberof DetailedStatusHistoryData
     */
    logLink?: string;
    /**
     * 
     * @type {Array<StatusCheckDetailedHistoryData>}
     * @memberof DetailedStatusHistoryData
     */
    checksDetailedHistory?: Array<StatusCheckDetailedHistoryData>;
}
/**
 * 
 * @export
 * @interface E2EComponent
 */
export interface E2EComponent {
    /**
     * 
     * @type {string}
     * @memberof E2EComponent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof E2EComponent
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof E2EComponent
     */
    startTime: string;
    /**
     * 
     * @type {number}
     * @memberof E2EComponent
     */
    elapsedTime: number;
}
/**
 * 
 * @export
 * @interface FailedTasksData
 */
export interface FailedTasksData {
    /**
     * 
     * @type {string}
     * @memberof FailedTasksData
     */
    inventoryHost?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedTasksData
     */
    ansibleHost?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedTasksData
     */
    taskName?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedTasksData
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface HistoryData
 */
export interface HistoryData {
    /**
     * 
     * @type {string}
     * @memberof HistoryData
     */
    env?: string;
    /**
     * 
     * @type {Array<CheckHistoryData>}
     * @memberof HistoryData
     */
    historyData?: Array<CheckHistoryData>;
}
/**
 * 
 * @export
 * @interface LoginData
 */
export interface LoginData {
    /**
     * 
     * @type {string}
     * @memberof LoginData
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginData
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface MattermostMessageResponse
 */
export interface MattermostMessageResponse {
    /**
     * 
     * @type {string}
     * @memberof MattermostMessageResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MattermostMessageResponse
     */
    createAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MattermostMessageResponse
     */
    updateAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MattermostMessageResponse
     */
    editAt?: number;
    /**
     * 
     * @type {number}
     * @memberof MattermostMessageResponse
     */
    deleteAt?: number;
    /**
     * 
     * @type {string}
     * @memberof MattermostMessageResponse
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof MattermostMessageResponse
     */
    channelId?: string;
    /**
     * 
     * @type {string}
     * @memberof MattermostMessageResponse
     */
    rootId?: string;
    /**
     * 
     * @type {string}
     * @memberof MattermostMessageResponse
     */
    originalId?: string;
    /**
     * 
     * @type {string}
     * @memberof MattermostMessageResponse
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof MattermostMessageResponse
     */
    type?: string;
    /**
     * 
     * @type {object}
     * @memberof MattermostMessageResponse
     */
    props?: object;
    /**
     * 
     * @type {string}
     * @memberof MattermostMessageResponse
     */
    hashtags?: string;
    /**
     * 
     * @type {string}
     * @memberof MattermostMessageResponse
     */
    pendingPostId?: string;
    /**
     * 
     * @type {number}
     * @memberof MattermostMessageResponse
     */
    replyCount?: number;
    /**
     * 
     * @type {number}
     * @memberof MattermostMessageResponse
     */
    lastReplyAt?: number;
    /**
     * 
     * @type {object}
     * @memberof MattermostMessageResponse
     */
    participants?: object;
    /**
     * 
     * @type {object}
     * @memberof MattermostMessageResponse
     */
    metadata?: object;
}
/**
 * 
 * @export
 * @interface MessageResponse
 */
export interface MessageResponse {
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    ts?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MuteStatus {
    Pending = 'pending',
    Active = 'active',
    Expired = 'expired',
    Finished = 'finished'
}

/**
 * 
 * @export
 * @interface MutedCheckData
 */
export interface MutedCheckData {
    /**
     * 
     * @type {number}
     * @memberof MutedCheckData
     */
    id?: number;
    /**
     * 
     * @type {Array<CheckData>}
     * @memberof MutedCheckData
     */
    checks?: Array<CheckData>;
    /**
     * 
     * @type {string}
     * @memberof MutedCheckData
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof MutedCheckData
     */
    dateStart?: string;
    /**
     * 
     * @type {string}
     * @memberof MutedCheckData
     */
    duration?: string;
    /**
     * 
     * @type {MuteStatus}
     * @memberof MutedCheckData
     */
    status?: MuteStatus;
}
/**
 * 
 * @export
 * @interface MutedChecksData
 */
export interface MutedChecksData {
    /**
     * 
     * @type {Array<MutedCheckData>}
     * @memberof MutedChecksData
     */
    mutedChecks?: Array<MutedCheckData>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ResultStatus {
    Ok = 'ok',
    Changed = 'changed',
    Unreachable = 'unreachable',
    Failed = 'failed',
    Skipped = 'skipped',
    Rescued = 'rescued',
    Ignored = 'ignored'
}

/**
 * 
 * @export
 * @interface SSLData
 */
export interface SSLData {
    /**
     * 
     * @type {boolean}
     * @memberof SSLData
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SSLData
     */
    publicKey?: string;
    /**
     * 
     * @type {string}
     * @memberof SSLData
     */
    privateKey?: string;
    /**
     * 
     * @type {string}
     * @memberof SSLData
     */
    cert?: string;
}
/**
 * 
 * @export
 * @interface SessionInfoData
 */
export interface SessionInfoData {
    /**
     * 
     * @type {string}
     * @memberof SessionInfoData
     */
    sessionId?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionInfoData
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionInfoData
     */
    displayName?: string;
}
/**
 * 
 * @export
 * @interface SlackMessageResponse
 */
export interface SlackMessageResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SlackMessageResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SlackMessageResponse
     */
    channel?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackMessageResponse
     */
    ts?: string;
    /**
     * 
     * @type {MessageResponse}
     * @memberof SlackMessageResponse
     */
    message?: MessageResponse;
    /**
     * 
     * @type {string}
     * @memberof SlackMessageResponse
     */
    warning?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackMessageResponse
     */
    error?: string;
    /**
     * 
     * @type {object}
     * @memberof SlackMessageResponse
     */
    responseMetadata?: object;
}
/**
 * 
 * @export
 * @interface StatLogData
 */
export interface StatLogData {
    /**
     * 
     * @type {number}
     * @memberof StatLogData
     */
    statLogId?: number;
    /**
     * 
     * @type {number}
     * @memberof StatLogData
     */
    configId?: number;
    /**
     * 
     * @type {string}
     * @memberof StatLogData
     */
    action?: string;
    /**
     * 
     * @type {string}
     * @memberof StatLogData
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof StatLogData
     */
    timestamp?: string;
}
/**
 * 
 * @export
 * @interface StatLogsData
 */
export interface StatLogsData {
    /**
     * 
     * @type {Array<StatLogData>}
     * @memberof StatLogsData
     */
    statLogDataList?: Array<StatLogData>;
}
/**
 * 
 * @export
 * @interface StateData
 */
export interface StateData {
    /**
     * 
     * @type {boolean}
     * @memberof StateData
     */
    hasChanges?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StateData
     */
    applyOnProgress?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StateData
     */
    resultShown?: boolean;
}
/**
 * 
 * @export
 * @interface StatusCheckData
 */
export interface StatusCheckData {
    /**
     * 
     * @type {number}
     * @memberof StatusCheckData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StatusCheckData
     */
    period?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusCheckData
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusCheckData
     */
    method?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StatusCheckData
     */
    headers?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StatusCheckData
     */
    options?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof StatusCheckData
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusCheckData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusCheckData
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StatusCheckData
     */
    active?: boolean;
}
/**
 * 
 * @export
 * @interface StatusCheckDetailedHistoryData
 */
export interface StatusCheckDetailedHistoryData {
    /**
     * 
     * @type {string}
     * @memberof StatusCheckDetailedHistoryData
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusCheckDetailedHistoryData
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusCheckDetailedHistoryData
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusCheckDetailedHistoryData
     */
    checksQuantity?: number;
    /**
     * 
     * @type {StatusCheckResult}
     * @memberof StatusCheckDetailedHistoryData
     */
    firstResult?: StatusCheckResult;
    /**
     * 
     * @type {StatusCheckResult}
     * @memberof StatusCheckDetailedHistoryData
     */
    lastResult?: StatusCheckResult;
}
/**
 * 
 * @export
 * @interface StatusCheckResult
 */
export interface StatusCheckResult {
    /**
     * 
     * @type {number}
     * @memberof StatusCheckResult
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StatusCheckResult
     */
    timestamp?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusCheckResult
     */
    statusValue?: number;
    /**
     * 
     * @type {string}
     * @memberof StatusCheckResult
     */
    reasonPhase?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusCheckResult
     */
    response?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusCheckResult
     */
    totalTime?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum StatusCheckStatus {
    Fail = 'fail',
    Warn = 'warn',
    Ok = 'ok'
}

/**
 * 
 * @export
 * @interface StatusCheckStatusData
 */
export interface StatusCheckStatusData {
    /**
     * 
     * @type {number}
     * @memberof StatusCheckStatusData
     */
    ok?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusCheckStatusData
     */
    warn?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusCheckStatusData
     */
    failed?: number;
}
/**
 * 
 * @export
 * @interface StatusData
 */
export interface StatusData {
    /**
     * 
     * @type {number}
     * @memberof StatusData
     */
    totalChangesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusData
     */
    appliedChangesCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StatusData
     */
    finished?: boolean;
}
/**
 * 
 * @export
 * @interface StatusHistoryData
 */
export interface StatusHistoryData {
    /**
     * 
     * @type {Array<CheckStatusHistoryData>}
     * @memberof StatusHistoryData
     */
    historyData?: Array<CheckStatusHistoryData>;
}
/**
 * 
 * @export
 * @interface StatusResultData
 */
export interface StatusResultData {
    /**
     * 
     * @type {string}
     * @memberof StatusResultData
     */
    timestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusResultData
     */
    period?: string;
    /**
     * 
     * @type {StatusCheckStatusData}
     * @memberof StatusResultData
     */
    status?: StatusCheckStatusData;
}
/**
 * 
 * @export
 * @interface UrlData
 */
export interface UrlData {
    /**
     * 
     * @type {string}
     * @memberof UrlData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlData
     */
    domainName?: string;
    /**
     * 
     * @type {SSLData}
     * @memberof UrlData
     */
    sslData?: SSLData;
    /**
     * 
     * @type {boolean}
     * @memberof UrlData
     */
    encryption?: boolean;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of stat logs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStatLog(options: any = {}): RequestArgs {
            const localVarPath = `/api/stat-log`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user password
         * @param {LoginData} loginData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoginData(loginData: LoginData, options: any = {}): RequestArgs {
            // verify required parameter 'loginData' is not null or undefined
            if (loginData === null || loginData === undefined) {
                throw new RequiredError('loginData','Required parameter loginData was null or undefined when calling updateLoginData.');
            }
            const localVarPath = `/api/account/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"LoginData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginData !== undefined ? loginData : {}) : (loginData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of stat logs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStatLog(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatLogsData> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).listStatLog(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update user password
         * @param {LoginData} loginData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoginData(loginData: LoginData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).updateLoginData(loginData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get list of stat logs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStatLog(options?: any) {
            return AccountApiFp(configuration).listStatLog(options)(axios, basePath);
        },
        /**
         * 
         * @summary Update user password
         * @param {LoginData} loginData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoginData(loginData: LoginData, options?: any) {
            return AccountApiFp(configuration).updateLoginData(loginData, options)(axios, basePath);
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Get list of stat logs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public listStatLog(options?: any) {
        return AccountApiFp(this.configuration).listStatLog(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update user password
     * @param {LoginData} loginData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateLoginData(loginData: LoginData, options?: any) {
        return AccountApiFp(this.configuration).updateLoginData(loginData, options)(this.axios, this.basePath);
    }

}


/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get current status by binding.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesStatuses(options: any = {}): RequestArgs {
            const localVarPath = `/api/activity-status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get current status by binding.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesStatuses(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesStatusesData> {
            const localVarAxiosArgs = ActivityApiAxiosParamCreator(configuration).getActivitiesStatuses(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary get current status by binding.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesStatuses(options?: any) {
            return ActivityApiFp(configuration).getActivitiesStatuses(options)(axios, basePath);
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @summary get current status by binding.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getActivitiesStatuses(options?: any) {
        return ActivityApiFp(this.configuration).getActivitiesStatuses(options)(this.axios, this.basePath);
    }

}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSession(options: any = {}): RequestArgs {
            const localVarPath = `/api/check-session`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginData} loginData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginData: LoginData, options: any = {}): RequestArgs {
            // verify required parameter 'loginData' is not null or undefined
            if (loginData === null || loginData === undefined) {
                throw new RequiredError('loginData','Required parameter loginData was null or undefined when calling login.');
            }
            const localVarPath = `/api/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"LoginData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginData !== undefined ? loginData : {}) : (loginData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options: any = {}): RequestArgs {
            const localVarPath = `/api/logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSession(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionInfoData> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).checkSession(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginData} loginData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginData: LoginData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionInfoData> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).login(loginData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).logout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Check session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSession(options?: any) {
            return AuthApiFp(configuration).checkSession(options)(axios, basePath);
        },
        /**
         * 
         * @summary Login
         * @param {LoginData} loginData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginData: LoginData, options?: any) {
            return AuthApiFp(configuration).login(loginData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any) {
            return AuthApiFp(configuration).logout(options)(axios, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Check session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public checkSession(options?: any) {
        return AuthApiFp(this.configuration).checkSession(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Login
     * @param {LoginData} loginData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(loginData: LoginData, options?: any) {
        return AuthApiFp(this.configuration).login(loginData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: any) {
        return AuthApiFp(this.configuration).logout(options)(this.axios, this.basePath);
    }

}


/**
 * ChangesApi - axios parameter creator
 * @export
 */
export const ChangesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Abort current config changes
         * @param {ConfigData} configData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortChanges(configData: ConfigData, options: any = {}): RequestArgs {
            // verify required parameter 'configData' is not null or undefined
            if (configData === null || configData === undefined) {
                throw new RequiredError('configData','Required parameter configData was null or undefined when calling abortChanges.');
            }
            const localVarPath = `/api/state/abort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ConfigData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(configData !== undefined ? configData : {}) : (configData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Apply current config changes
         * @param {ConfigData} configData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyChanges(configData: ConfigData, options: any = {}): RequestArgs {
            // verify required parameter 'configData' is not null or undefined
            if (configData === null || configData === undefined) {
                throw new RequiredError('configData','Required parameter configData was null or undefined when calling applyChanges.');
            }
            const localVarPath = `/api/state/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ConfigData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(configData !== undefined ? configData : {}) : (configData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if there are changes and apply in progress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkState(options: any = {}): RequestArgs {
            const localVarPath = `/api/state/check-state`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current changes status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangesStatus(options: any = {}): RequestArgs {
            const localVarPath = `/api/state/get-status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify current changes status shown flag
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyChangesShownState(body: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling modifyChangesShownState.');
            }
            const localVarPath = `/api/state/modify-state`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"boolean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChangesApi - functional programming interface
 * @export
 */
export const ChangesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Abort current config changes
         * @param {ConfigData} configData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortChanges(configData: ConfigData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigData> {
            const localVarAxiosArgs = ChangesApiAxiosParamCreator(configuration).abortChanges(configData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Apply current config changes
         * @param {ConfigData} configData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyChanges(configData: ConfigData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigData> {
            const localVarAxiosArgs = ChangesApiAxiosParamCreator(configuration).applyChanges(configData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check if there are changes and apply in progress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkState(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateData> {
            const localVarAxiosArgs = ChangesApiAxiosParamCreator(configuration).checkState(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get current changes status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangesStatus(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusData> {
            const localVarAxiosArgs = ChangesApiAxiosParamCreator(configuration).getChangesStatus(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modify current changes status shown flag
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyChangesShownState(body: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ChangesApiAxiosParamCreator(configuration).modifyChangesShownState(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ChangesApi - factory interface
 * @export
 */
export const ChangesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Abort current config changes
         * @param {ConfigData} configData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortChanges(configData: ConfigData, options?: any) {
            return ChangesApiFp(configuration).abortChanges(configData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Apply current config changes
         * @param {ConfigData} configData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyChanges(configData: ConfigData, options?: any) {
            return ChangesApiFp(configuration).applyChanges(configData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Check if there are changes and apply in progress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkState(options?: any) {
            return ChangesApiFp(configuration).checkState(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get current changes status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangesStatus(options?: any) {
            return ChangesApiFp(configuration).getChangesStatus(options)(axios, basePath);
        },
        /**
         * 
         * @summary Modify current changes status shown flag
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyChangesShownState(body: boolean, options?: any) {
            return ChangesApiFp(configuration).modifyChangesShownState(body, options)(axios, basePath);
        },
    };
};

/**
 * ChangesApi - object-oriented interface
 * @export
 * @class ChangesApi
 * @extends {BaseAPI}
 */
export class ChangesApi extends BaseAPI {
    /**
     * 
     * @summary Abort current config changes
     * @param {ConfigData} configData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangesApi
     */
    public abortChanges(configData: ConfigData, options?: any) {
        return ChangesApiFp(this.configuration).abortChanges(configData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Apply current config changes
     * @param {ConfigData} configData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangesApi
     */
    public applyChanges(configData: ConfigData, options?: any) {
        return ChangesApiFp(this.configuration).applyChanges(configData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Check if there are changes and apply in progress
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangesApi
     */
    public checkState(options?: any) {
        return ChangesApiFp(this.configuration).checkState(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get current changes status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangesApi
     */
    public getChangesStatus(options?: any) {
        return ChangesApiFp(this.configuration).getChangesStatus(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modify current changes status shown flag
     * @param {boolean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangesApi
     */
    public modifyChangesShownState(body: boolean, options?: any) {
        return ChangesApiFp(this.configuration).modifyChangesShownState(body, options)(this.axios, this.basePath);
    }

}


/**
 * ChecksApi - axios parameter creator
 * @export
 */
export const ChecksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get list of checks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecksList(options: any = {}): RequestArgs {
            const localVarPath = `/api/checks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecksApi - functional programming interface
 * @export
 */
export const ChecksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get list of checks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecksList(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecksData> {
            const localVarAxiosArgs = ChecksApiAxiosParamCreator(configuration).getChecksList(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ChecksApi - factory interface
 * @export
 */
export const ChecksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary get list of checks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecksList(options?: any) {
            return ChecksApiFp(configuration).getChecksList(options)(axios, basePath);
        },
    };
};

/**
 * ChecksApi - object-oriented interface
 * @export
 * @class ChecksApi
 * @extends {BaseAPI}
 */
export class ChecksApi extends BaseAPI {
    /**
     * 
     * @summary get list of checks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecksApi
     */
    public getChecksList(options?: any) {
        return ChecksApiFp(this.configuration).getChecksList(options)(this.axios, this.basePath);
    }

}


/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get config data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig(options: any = {}): RequestArgs {
            const localVarPath = `/api/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get config changes list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigChanges(options: any = {}): RequestArgs {
            const localVarPath = `/api/config/get-changes-list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rollback config data
         * @param {ConfigData} configData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackConfig(configData: ConfigData, options: any = {}): RequestArgs {
            // verify required parameter 'configData' is not null or undefined
            if (configData === null || configData === undefined) {
                throw new RequiredError('configData','Required parameter configData was null or undefined when calling rollbackConfig.');
            }
            const localVarPath = `/api/config/rollback`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ConfigData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(configData !== undefined ? configData : {}) : (configData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update config data
         * @param {ConfigData} configData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfig(configData: ConfigData, options: any = {}): RequestArgs {
            // verify required parameter 'configData' is not null or undefined
            if (configData === null || configData === undefined) {
                throw new RequiredError('configData','Required parameter configData was null or undefined when calling updateConfig.');
            }
            const localVarPath = `/api/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ConfigData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(configData !== undefined ? configData : {}) : (configData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get config data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigData> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).getConfig(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get config changes list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigChanges(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).getConfigChanges(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Rollback config data
         * @param {ConfigData} configData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackConfig(configData: ConfigData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigData> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).rollbackConfig(configData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update config data
         * @param {ConfigData} configData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfig(configData: ConfigData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigData> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).updateConfig(configData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get config data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig(options?: any) {
            return ConfigApiFp(configuration).getConfig(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get config changes list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigChanges(options?: any) {
            return ConfigApiFp(configuration).getConfigChanges(options)(axios, basePath);
        },
        /**
         * 
         * @summary Rollback config data
         * @param {ConfigData} configData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackConfig(configData: ConfigData, options?: any) {
            return ConfigApiFp(configuration).rollbackConfig(configData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update config data
         * @param {ConfigData} configData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfig(configData: ConfigData, options?: any) {
            return ConfigApiFp(configuration).updateConfig(configData, options)(axios, basePath);
        },
    };
};

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
    /**
     * 
     * @summary Get config data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getConfig(options?: any) {
        return ConfigApiFp(this.configuration).getConfig(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get config changes list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getConfigChanges(options?: any) {
        return ConfigApiFp(this.configuration).getConfigChanges(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Rollback config data
     * @param {ConfigData} configData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public rollbackConfig(configData: ConfigData, options?: any) {
        return ConfigApiFp(this.configuration).rollbackConfig(configData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update config data
     * @param {ConfigData} configData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public updateConfig(configData: ConfigData, options?: any) {
        return ConfigApiFp(this.configuration).updateConfig(configData, options)(this.axios, this.basePath);
    }

}


/**
 * ConfigCheckApi - axios parameter creator
 * @export
 */
export const ConfigCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get config check log by Id.
         * @param {number} checkId Check id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckLog(checkId: number, options: any = {}): RequestArgs {
            // verify required parameter 'checkId' is not null or undefined
            if (checkId === null || checkId === undefined) {
                throw new RequiredError('checkId','Required parameter checkId was null or undefined when calling getCheckLog.');
            }
            const localVarPath = `/api/config-check/{checkId}`
                .replace(`{${"checkId"}}`, encodeURIComponent(String(checkId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get detailed config check history.
         * @param {string} historyId History container identifier (bindId_startTimeSec_d/h).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedHistory(historyId: string, options: any = {}): RequestArgs {
            // verify required parameter 'historyId' is not null or undefined
            if (historyId === null || historyId === undefined) {
                throw new RequiredError('historyId','Required parameter historyId was null or undefined when calling getDetailedHistory.');
            }
            const localVarPath = `/api/config-check/history-detailed`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (historyId !== undefined) {
                localVarQueryParameter['historyId'] = historyId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get config check history
         * @param {string} period Period (hour or day).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(period: string, options: any = {}): RequestArgs {
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getHistory.');
            }
            const localVarPath = `/api/config-check/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigCheckApi - functional programming interface
 * @export
 */
export const ConfigCheckApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get config check log by Id.
         * @param {number} checkId Check id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckLog(checkId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = ConfigCheckApiAxiosParamCreator(configuration).getCheckLog(checkId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get detailed config check history.
         * @param {string} historyId History container identifier (bindId_startTimeSec_d/h).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedHistory(historyId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedHistoryData> {
            const localVarAxiosArgs = ConfigCheckApiAxiosParamCreator(configuration).getDetailedHistory(historyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get config check history
         * @param {string} period Period (hour or day).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(period: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryData> {
            const localVarAxiosArgs = ConfigCheckApiAxiosParamCreator(configuration).getHistory(period, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConfigCheckApi - factory interface
 * @export
 */
export const ConfigCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get config check log by Id.
         * @param {number} checkId Check id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckLog(checkId: number, options?: any) {
            return ConfigCheckApiFp(configuration).getCheckLog(checkId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get detailed config check history.
         * @param {string} historyId History container identifier (bindId_startTimeSec_d/h).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedHistory(historyId: string, options?: any) {
            return ConfigCheckApiFp(configuration).getDetailedHistory(historyId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get config check history
         * @param {string} period Period (hour or day).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(period: string, options?: any) {
            return ConfigCheckApiFp(configuration).getHistory(period, options)(axios, basePath);
        },
    };
};

/**
 * ConfigCheckApi - object-oriented interface
 * @export
 * @class ConfigCheckApi
 * @extends {BaseAPI}
 */
export class ConfigCheckApi extends BaseAPI {
    /**
     * 
     * @summary Get config check log by Id.
     * @param {number} checkId Check id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigCheckApi
     */
    public getCheckLog(checkId: number, options?: any) {
        return ConfigCheckApiFp(this.configuration).getCheckLog(checkId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get detailed config check history.
     * @param {string} historyId History container identifier (bindId_startTimeSec_d/h).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigCheckApi
     */
    public getDetailedHistory(historyId: string, options?: any) {
        return ConfigCheckApiFp(this.configuration).getDetailedHistory(historyId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get config check history
     * @param {string} period Period (hour or day).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigCheckApi
     */
    public getHistory(period: string, options?: any) {
        return ConfigCheckApiFp(this.configuration).getHistory(period, options)(this.axios, this.basePath);
    }

}


/**
 * MuteApi - axios parameter creator
 * @export
 */
export const MuteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create the muted check.
         * @param {MutedCheckData} mutedCheckData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMutedChecks(mutedCheckData: MutedCheckData, options: any = {}): RequestArgs {
            // verify required parameter 'mutedCheckData' is not null or undefined
            if (mutedCheckData === null || mutedCheckData === undefined) {
                throw new RequiredError('mutedCheckData','Required parameter mutedCheckData was null or undefined when calling createMutedChecks.');
            }
            const localVarPath = `/api/silence`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MutedCheckData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mutedCheckData !== undefined ? mutedCheckData : {}) : (mutedCheckData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete muted check by the id.
         * @param {number} mutedCheckId Muted check id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMutedCheck(mutedCheckId: number, options: any = {}): RequestArgs {
            // verify required parameter 'mutedCheckId' is not null or undefined
            if (mutedCheckId === null || mutedCheckId === undefined) {
                throw new RequiredError('mutedCheckId','Required parameter mutedCheckId was null or undefined when calling deleteMutedCheck.');
            }
            const localVarPath = `/api/silence/{mutedCheckId}`
                .replace(`{${"mutedCheckId"}}`, encodeURIComponent(String(mutedCheckId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get list of muted checks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMutedChecks(options: any = {}): RequestArgs {
            const localVarPath = `/api/silence`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the muted check.
         * @param {number} mutedCheckId Muted check id.
         * @param {MutedCheckData} mutedCheckData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMutedCheck(mutedCheckId: number, mutedCheckData: MutedCheckData, options: any = {}): RequestArgs {
            // verify required parameter 'mutedCheckId' is not null or undefined
            if (mutedCheckId === null || mutedCheckId === undefined) {
                throw new RequiredError('mutedCheckId','Required parameter mutedCheckId was null or undefined when calling updateMutedCheck.');
            }
            // verify required parameter 'mutedCheckData' is not null or undefined
            if (mutedCheckData === null || mutedCheckData === undefined) {
                throw new RequiredError('mutedCheckData','Required parameter mutedCheckData was null or undefined when calling updateMutedCheck.');
            }
            const localVarPath = `/api/silence/{mutedCheckId}`
                .replace(`{${"mutedCheckId"}}`, encodeURIComponent(String(mutedCheckId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MutedCheckData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mutedCheckData !== undefined ? mutedCheckData : {}) : (mutedCheckData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MuteApi - functional programming interface
 * @export
 */
export const MuteApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create the muted check.
         * @param {MutedCheckData} mutedCheckData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMutedChecks(mutedCheckData: MutedCheckData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutedCheckData> {
            const localVarAxiosArgs = MuteApiAxiosParamCreator(configuration).createMutedChecks(mutedCheckData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary delete muted check by the id.
         * @param {number} mutedCheckId Muted check id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMutedCheck(mutedCheckId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MuteApiAxiosParamCreator(configuration).deleteMutedCheck(mutedCheckId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get list of muted checks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMutedChecks(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutedChecksData> {
            const localVarAxiosArgs = MuteApiAxiosParamCreator(configuration).getMutedChecks(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary update the muted check.
         * @param {number} mutedCheckId Muted check id.
         * @param {MutedCheckData} mutedCheckData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMutedCheck(mutedCheckId: number, mutedCheckData: MutedCheckData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutedCheckData> {
            const localVarAxiosArgs = MuteApiAxiosParamCreator(configuration).updateMutedCheck(mutedCheckId, mutedCheckData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MuteApi - factory interface
 * @export
 */
export const MuteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary create the muted check.
         * @param {MutedCheckData} mutedCheckData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMutedChecks(mutedCheckData: MutedCheckData, options?: any) {
            return MuteApiFp(configuration).createMutedChecks(mutedCheckData, options)(axios, basePath);
        },
        /**
         * 
         * @summary delete muted check by the id.
         * @param {number} mutedCheckId Muted check id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMutedCheck(mutedCheckId: number, options?: any) {
            return MuteApiFp(configuration).deleteMutedCheck(mutedCheckId, options)(axios, basePath);
        },
        /**
         * 
         * @summary get list of muted checks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMutedChecks(options?: any) {
            return MuteApiFp(configuration).getMutedChecks(options)(axios, basePath);
        },
        /**
         * 
         * @summary update the muted check.
         * @param {number} mutedCheckId Muted check id.
         * @param {MutedCheckData} mutedCheckData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMutedCheck(mutedCheckId: number, mutedCheckData: MutedCheckData, options?: any) {
            return MuteApiFp(configuration).updateMutedCheck(mutedCheckId, mutedCheckData, options)(axios, basePath);
        },
    };
};

/**
 * MuteApi - object-oriented interface
 * @export
 * @class MuteApi
 * @extends {BaseAPI}
 */
export class MuteApi extends BaseAPI {
    /**
     * 
     * @summary create the muted check.
     * @param {MutedCheckData} mutedCheckData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuteApi
     */
    public createMutedChecks(mutedCheckData: MutedCheckData, options?: any) {
        return MuteApiFp(this.configuration).createMutedChecks(mutedCheckData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary delete muted check by the id.
     * @param {number} mutedCheckId Muted check id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuteApi
     */
    public deleteMutedCheck(mutedCheckId: number, options?: any) {
        return MuteApiFp(this.configuration).deleteMutedCheck(mutedCheckId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get list of muted checks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuteApi
     */
    public getMutedChecks(options?: any) {
        return MuteApiFp(this.configuration).getMutedChecks(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary update the muted check.
     * @param {number} mutedCheckId Muted check id.
     * @param {MutedCheckData} mutedCheckData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuteApi
     */
    public updateMutedCheck(mutedCheckId: number, mutedCheckData: MutedCheckData, options?: any) {
        return MuteApiFp(this.configuration).updateMutedCheck(mutedCheckId, mutedCheckData, options)(this.axios, this.basePath);
    }

}


/**
 * StatusCheckApi - axios parameter creator
 * @export
 */
export const StatusCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get detailed status check history.
         * @param {string} statusHistoryId History container identifier (statusCheckId_startTimeSec_d/h).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusCheckDetailedHistory(statusHistoryId: string, options: any = {}): RequestArgs {
            // verify required parameter 'statusHistoryId' is not null or undefined
            if (statusHistoryId === null || statusHistoryId === undefined) {
                throw new RequiredError('statusHistoryId','Required parameter statusHistoryId was null or undefined when calling getStatusCheckDetailedHistory.');
            }
            const localVarPath = `/api/health-check/history-detailed`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (statusHistoryId !== undefined) {
                localVarQueryParameter['statusHistoryId'] = statusHistoryId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get config check log by Id.
         * @param {string} statusHistoryId History container identifier (statusCheckId_startTimeSec_d/h).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusCheckLog(statusHistoryId: string, options: any = {}): RequestArgs {
            // verify required parameter 'statusHistoryId' is not null or undefined
            if (statusHistoryId === null || statusHistoryId === undefined) {
                throw new RequiredError('statusHistoryId','Required parameter statusHistoryId was null or undefined when calling getStatusCheckLog.');
            }
            const localVarPath = `/api/health-check/{statusHistoryId}`
                .replace(`{${"statusHistoryId"}}`, encodeURIComponent(String(statusHistoryId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get status check history
         * @param {string} period Period (hour or day).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusChecksHistory(period: string, options: any = {}): RequestArgs {
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getStatusChecksHistory.');
            }
            const localVarPath = `/api/health-check/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusCheckApi - functional programming interface
 * @export
 */
export const StatusCheckApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get detailed status check history.
         * @param {string} statusHistoryId History container identifier (statusCheckId_startTimeSec_d/h).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusCheckDetailedHistory(statusHistoryId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedStatusHistoryData> {
            const localVarAxiosArgs = StatusCheckApiAxiosParamCreator(configuration).getStatusCheckDetailedHistory(statusHistoryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get config check log by Id.
         * @param {string} statusHistoryId History container identifier (statusCheckId_startTimeSec_d/h).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusCheckLog(statusHistoryId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = StatusCheckApiAxiosParamCreator(configuration).getStatusCheckLog(statusHistoryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get status check history
         * @param {string} period Period (hour or day).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusChecksHistory(period: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusHistoryData> {
            const localVarAxiosArgs = StatusCheckApiAxiosParamCreator(configuration).getStatusChecksHistory(period, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StatusCheckApi - factory interface
 * @export
 */
export const StatusCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get detailed status check history.
         * @param {string} statusHistoryId History container identifier (statusCheckId_startTimeSec_d/h).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusCheckDetailedHistory(statusHistoryId: string, options?: any) {
            return StatusCheckApiFp(configuration).getStatusCheckDetailedHistory(statusHistoryId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get config check log by Id.
         * @param {string} statusHistoryId History container identifier (statusCheckId_startTimeSec_d/h).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusCheckLog(statusHistoryId: string, options?: any) {
            return StatusCheckApiFp(configuration).getStatusCheckLog(statusHistoryId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get status check history
         * @param {string} period Period (hour or day).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusChecksHistory(period: string, options?: any) {
            return StatusCheckApiFp(configuration).getStatusChecksHistory(period, options)(axios, basePath);
        },
    };
};

/**
 * StatusCheckApi - object-oriented interface
 * @export
 * @class StatusCheckApi
 * @extends {BaseAPI}
 */
export class StatusCheckApi extends BaseAPI {
    /**
     * 
     * @summary Get detailed status check history.
     * @param {string} statusHistoryId History container identifier (statusCheckId_startTimeSec_d/h).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusCheckApi
     */
    public getStatusCheckDetailedHistory(statusHistoryId: string, options?: any) {
        return StatusCheckApiFp(this.configuration).getStatusCheckDetailedHistory(statusHistoryId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get config check log by Id.
     * @param {string} statusHistoryId History container identifier (statusCheckId_startTimeSec_d/h).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusCheckApi
     */
    public getStatusCheckLog(statusHistoryId: string, options?: any) {
        return StatusCheckApiFp(this.configuration).getStatusCheckLog(statusHistoryId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get status check history
     * @param {string} period Period (hour or day).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusCheckApi
     */
    public getStatusChecksHistory(period: string, options?: any) {
        return StatusCheckApiFp(this.configuration).getStatusChecksHistory(period, options)(this.axios, this.basePath);
    }

}


